import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Image,
  Row,
  Tab,
  Nav,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import ChangeMobileNumberModal from "./ChangeMobileNumberModal";
import ChangeUserNameModal from "./ChangeUserNameModal";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeEmailAddressModal from "./ChangeEmailAddressModal";
import DeleteAccountModal from "./DeleteAccountModal";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import {
  profileStart,
  updateProfileStart,
} from "../../store/slices/AdminSlice";
import useLogout from "../../hooks/useLogout";
import VerifyKYCModal from "./VerifyKYCModal";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import OTPVerificationModal from "../Auth/OTPVerificationModal";
import ApiKeyModal from "./ApiKeyModal";
import { useTranslation } from "react-multi-lang";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";

const AccountIndex = (props) => {
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const t = useTranslation("account_settings");
  const profile = useSelector((state) => state.admin.profile);
  const updateProfile = useSelector((state) => state.admin.updateProfile);
  const kycStatusUpdate = useSelector((state) => state.admin.kycStatusUpdate);
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
  const [changeUserName, setChangeUserName] = useState(false);
  const [changeMobileNumber, setchangeMobileNumber] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [newEmail, setNewEmail] = useState();

  const closeChangeUserNameModal = () => {
    setChangeUserName(false);
  };
  const closeChangeMobileNumberModal = () => {
    setchangeMobileNumber(false);
  };

  const [changePassword, setChangePassword] = useState(false);

  const closeChangePasswordModal = () => {
    setChangePassword(false);
  };

  const [changeEmailAddress, setChangeEmailAddress] = useState(false);

  const closeChangeEmailAddressModal = () => {
    setChangeEmailAddress(false);
  };

  const [deleteAccount, setDeleteAccount] = useState(false);

  const closeDeleteAccountModal = () => {
    setDeleteAccount(false);
  };

  const [verifyKYC, setVerifyKYC] = useState(false);

  const closeVerifyKYCModal = () => {
    setVerifyKYC(false);
  };

  const [apiKeyModal, setApiKeyModal] = useState(false);

  const closeApiKeyModal = () => {
    setApiKeyModal(false);
  };

  const handleUpdateProfilePicture = (image) => {
    image &&
      dispatch(
        updateProfileStart({
          picture: image,
          name: profile.data.name,
          email: profile.data.email,
          mobile: profile.data.mobile,
          country_code: profile.data.country_code,
          citizenship: profile.data.citizenship,
          residence: profile.data.residence,
        })
      );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycStatusUpdate.loading &&
      Object.keys(kycStatusUpdate.data).length > 0
    ) {
      closeVerifyKYCModal();
    }
    setSkipRender(false);
  }, [kycStatusUpdate]);

  useEffect(() => {
    if (
      !skipRender &&
      !updateEmailCode.loading &&
      Object.keys(updateEmailCode.data).length > 0
    ) {
      setShowVerify(newEmail);
    }
    setSkipRender(false);
  }, [updateEmailCode]);

  const zeroPad = (num, places) => String(num).padStart(places, '0')

  return profile.loading ? (
    <PageLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      <div className="profile-account-sec">
        <div className="settlement-top-sec">
          <h3>{t("heading")}</h3>
        </div>
        <div className="profile-account-box">
          <div className="profile-account-header-sec">
            <div className="profile-account-header-left-sec">
              <div className="profile-account-user-details-sec">
                <div className="profile-account-user-img-sec">
                  {updateProfile.buttonDisable ? <CustomLazyLoad
                    className="profile-account-user-img"
                    src={window.location.origin + "/img/image-pulse.svg"}
                    type="image/png"
                  /> : <CustomLazyLoad
                    className="profile-account-user-img"
                    src={
                      profile.data.picture ||
                      window.location.origin + "/img/user-img.svg"
                    }
                    type="image/png"
                  />}
                  <label className="profile-account-user-img-edit-icon mb-3">
                    <input
                      type="file"
                      accept="image/png, image/jpeg,, image/jpg"
                      size="60"
                      disabled={updateProfile.buttonDisable}
                      onChange={(e) =>
                        handleUpdateProfilePicture(e.target.files[0])
                      }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                      fill="#5A36A8"
                    >
                      <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                    </svg>
                  </label>
                </div>
                <div className="profile-account-user-info">
                  <h4>{profile.data.name}</h4>
                  <p>
                    {t("manage_your_personal_information_email_password_more")}
                  </p>
                  <ul className="list-unstyled profile-account-user-info-list">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 16 14"
                      >
                        <path
                          fill="#020E30"
                          d="M12.667.5H3.333c-.883 0-1.73.313-2.356.866C.353 1.92.001 2.671 0 3.455v7.09c.001.784.353 1.535.977 2.089.625.553 1.473.865 2.356.866h9.334c.883 0 1.73-.313 2.356-.866.624-.554.976-1.305.977-2.088V3.455c-.001-.784-.353-1.535-.977-2.089C14.398.813 13.55.501 12.667.5zM3.333 1.682h9.334c.399 0 .789.107 1.119.306.33.199.586.48.734.809L9.415 7.323A2.143 2.143 0 018 7.84c-.53 0-1.039-.187-1.415-.518L1.48 2.797c.148-.329.404-.61.734-.81.33-.198.72-.304 1.12-.305zm9.334 10.636H3.333c-.53 0-1.039-.187-1.414-.519a1.677 1.677 0 01-.586-1.253V4.34l4.31 3.817c.625.553 1.473.864 2.357.864.884 0 1.732-.31 2.357-.864l4.31-3.817v6.205c0 .47-.211.92-.586 1.253-.375.332-.884.52-1.414.52z"
                        ></path>
                      </svg>
                      <span>{profile.data.email}</span>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#0C0E10"
                          fillRule="evenodd"
                          d="M1.607 1.056C3.143-.48 5.736-.363 6.806 1.554l.593 1.063c.698 1.251.4 2.83-.622 3.866a.576.576 0 00-.095.308c-.012.234.072.776.912 1.615.84.84 1.38.924 1.616.912a.576.576 0 00.308-.095c1.034-1.022 2.614-1.32 3.865-.622l1.063.595c1.917 1.07 2.034 3.66.498 5.197-.822.823-1.914 1.553-3.199 1.6-1.902.074-5.062-.418-8.19-3.548C.425 9.317-.066 6.158.005 4.255c.049-1.285.78-2.377 1.6-3.199zm4 1.166c-.548-.982-2.02-1.207-3.03-.195-.709.708-1.17 1.49-1.2 2.28-.06 1.587.332 4.354 3.147 7.168 2.817 2.817 5.583 3.209 7.17 3.149.788-.03 1.572-.492 2.28-1.2 1.01-1.011.786-2.484-.196-3.031l-1.064-.594c-.66-.368-1.59-.243-2.242.41-.064.064-.472.444-1.196.479-.74.037-1.637-.296-2.651-1.311-1.016-1.015-1.349-1.912-1.312-2.654.034-.723.416-1.131.479-1.195.653-.652.778-1.581.41-2.242l-.595-1.064z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>
                        +{profile.data.country_code} {profile.data.mobile}
                        <button
                          type="button"
                          class="btn btn-outline-light "
                          onClick={() => setchangeMobileNumber(true)}
                        >
                          <svg
                            className="profile-account-mobile-img-edit-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            width="30"
                            height="30"
                            fill="#5A36A8"
                          >
                            <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                          </svg>
                        </button>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {profile.data.kyc_status == 3 ? (
              <div className="profile-account-header-right-sec">
                <div className="profile-account-header-kyc-verified-btn">
                  <Image
                    className="kyc-verified-icon"
                    src={window.location.origin + "/img/kyc-verified.svg"}
                    type="image/png"
                  />
                </div>
                <h5>{t("kyc_verified")}</h5>
              </div>
            ) : (
              <div className="profile-account-header-kyc-verified-btn">
                <Button
                  className="efi-auth-btn"
                  onClick={() => setVerifyKYC(true)}
                >
                  {t("verify_kyc")}
                </Button>
              </div>
            )}
          </div>
          <div className="profile-account-body-sec">
            <div className="profile-account-list-box">
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 24"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1 22.313v-1.184a7.104 7.104 0 017.104-7.105h4.736a7.104 7.104 0 017.104 7.104v1.184M10.472 10.472a4.736 4.736 0 110-9.472 4.736 4.736 0 010 9.472z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("api_key")}</h4>
                    <p>***************</p>
                  </div>
                </div>
                <div
                  className="profile-account-list-btn-sec"
                  style={{ display: "flex", gap: "1em" }}
                >
                  {/* {profile.data.api_key_generated ? ( */}
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setApiKeyModal(1)}
                  >
                    {t("view")}
                  </Button>
                  {/* ) : null} */}
                  {/* <Button
                    className="efi-auth-btn"
                    onClick={() => setApiKeyModal(2)}
                  >
                    {profile.data.api_key_generated
                      ? t("regenerate")
                      : t("generate")}
                  </Button> */}
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 24"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1 22.313v-1.184a7.104 7.104 0 017.104-7.105h4.736a7.104 7.104 0 017.104 7.104v1.184M10.472 10.472a4.736 4.736 0 110-9.472 4.736 4.736 0 010 9.472z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("salt_key")}</h4>
                    <p>***************</p>
                  </div>
                </div>
                <div
                  className="profile-account-list-btn-sec"
                  style={{ display: "flex", gap: "1em" }}
                >
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setApiKeyModal(2)}
                  >
                    {t("view")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 24"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1 22.313v-1.184a7.104 7.104 0 017.104-7.105h4.736a7.104 7.104 0 017.104 7.104v1.184M10.472 10.472a4.736 4.736 0 110-9.472 4.736 4.736 0 010 9.472z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("merchant_id")}</h4>

                    <div className="payment-link-created-card">
                      <div className="payment-link-created-left-sec">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width={16} height={16}>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                        </svg>
                        <span>
                          EFI00{zeroPad(profile.data.user_id, 3)}
                        </span>
                      </div>
                      <div className="payment-link-created-right-sec">
                        <CopyToClipboard
                          text={"EFI00" + zeroPad(profile.data.user_id, 3)}
                          onCopy={() => getSuccessNotificationMessage(t("merchant_id_copied_to_clipboard"))}>
                          <Button className="copy-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              fill="#5C9EEB"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                            </svg>
                            <span>{t("copy")}</span>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 24"
                    >
                      <path
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1 22.313v-1.184a7.104 7.104 0 017.104-7.105h4.736a7.104 7.104 0 017.104 7.104v1.184M10.472 10.472a4.736 4.736 0 110-9.472 4.736 4.736 0 010 9.472z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("username")}</h4>
                    <p>{profile.data.name}</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setChangeUserName(true)}
                  >
                    {t("edit")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 19"
                    >
                      <path
                        fill="#020E30"
                        d="M16.625.313H4.375a4.54 4.54 0 00-3.092 1.2C.463 2.278 0 3.318 0 4.402v9.819c.001 1.084.463 2.124 1.283 2.89a4.54 4.54 0 003.092 1.2h12.25a4.54 4.54 0 003.092-1.2c.82-.766 1.282-1.806 1.283-2.89V4.403c-.001-1.084-.463-2.124-1.283-2.89a4.54 4.54 0 00-3.092-1.2zM4.375 1.948h12.25a2.755 2.755 0 011.47.424c.433.275.768.665.963 1.12l-6.701 6.266c-.493.46-1.161.717-1.857.717a2.727 2.727 0 01-1.857-.717l-6.7-6.266c.194-.455.53-.845.963-1.12a2.755 2.755 0 011.469-.424zm12.25 14.727H4.375a2.72 2.72 0 01-1.856-.719 2.376 2.376 0 01-.769-1.735V5.63l5.656 5.285a4.54 4.54 0 003.094 1.196 4.54 4.54 0 003.094-1.196l5.656-5.285v8.59c0 .652-.277 1.276-.769 1.736a2.72 2.72 0 01-1.856.72z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("change_email_address")}</h4>
                    <p>{profile.data.email}</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setChangeEmailAddress(true)}
                  >
                    {t("edit")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 25"
                    >
                      <path
                        fill="#000"
                        d="M17.949 2.947L10 .313 2.051 2.947A2.982 2.982 0 000 5.78v6.524c0 7.524 9.2 11.68 9.594 11.852l.354.157.368-.122C10.711 24.058 20 20.88 20 12.303V5.78a2.984 2.984 0 00-2.051-2.832zM18 12.304c0 6.263-6.349 9.216-7.953 9.86C8.44 21.361 2 17.797 2 12.305V5.778a.994.994 0 01.684-.944L10 2.41l7.316 2.425A.992.992 0 0118 5.78v6.524z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("change_password")}</h4>
                    <p>*******************</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setChangePassword(true)}
                  >
                    {t("edit")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#000"
                        d="M9.813.313A9.752 9.752 0 000 10.124a9.752 9.752 0 009.813 9.813 9.752 9.752 0 009.812-9.813A9.752 9.752 0 009.812.312zm0 18.223c-4.626 0-8.411-3.785-8.411-8.411 0-4.626 3.785-8.41 8.41-8.41 4.626 0 8.411 3.784 8.411 8.41s-3.785 8.41-8.41 8.41z"
                      ></path>
                      <path
                        fill="#000"
                        d="M13.597 15.031l-3.784-3.785-3.785 3.785-1.122-1.121 3.785-3.785L4.906 6.34 6.028 5.22l3.785 3.785 3.784-3.785L14.72 6.34l-3.785 3.785 3.785 3.785-1.122 1.121z"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("delete_your_account")}</h4>
                    <p>{t("if_you_want_to_stop_using_inefipay")}</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setDeleteAccount(true)}
                  >
                    {t("delete")}
                  </Button>
                </div>
              </div>
              <div className="profile-account-list-card">
                <div className="profile-account-list-details-sec">
                  <div className="profile-account-list-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 19 20"
                    >
                      <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7.594 10.137h9.652m0 0L14.488 7.59m2.758 2.546l-2.758 2.545M16 16.17a8.785 8.785 0 01-4.455 2.415c-1.7.355-3.47.208-5.083-.422a8.669 8.669 0 01-3.968-3.113A8.29 8.29 0 011 10.312a8.29 8.29 0 011.494-4.737 8.669 8.669 0 013.968-3.113 8.955 8.955 0 015.083-.422A8.785 8.785 0 0116 4.455"
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-account-list-info">
                    <h4>{t("logout_of_all_device")}</h4>
                    <p>{t("if_you_notice_any_para")}</p>
                  </div>
                </div>
                <div className="profile-account-list-btn-sec">
                  <Button className="efi-auth-btn" onClick={logout}>
                    {t("logout")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New Account  UI  */}
      {/* <div className="efi-widget-profile">
        <Row className="align-items-center">
          <Col md={6} lg={6}>
            <div className="efi-profile-details-wrapped">
              <div className="efi-profile-details-status">
                <div className="efi-profile-details-card">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 460.8 460.8"
                  >
                    <path
                      fill="#5a36a8"
                      d="M230.432 0c-65.829 0-119.641 53.812-119.641 119.641s53.812 119.641 119.641 119.641 119.641-53.812 119.641-119.641S296.261 0 230.432 0zm205.323 334.89c-3.135-7.837-7.314-15.151-12.016-21.943-24.033-35.527-61.126-59.037-102.922-64.784-5.224-.522-10.971.522-15.151 3.657-21.943 16.196-48.065 24.555-75.233 24.555s-53.29-8.359-75.233-24.555c-4.18-3.135-9.927-4.702-15.151-3.657-41.796 5.747-79.412 29.257-102.922 64.784-4.702 6.792-8.882 14.629-12.016 21.943-1.567 3.135-1.045 6.792.522 9.927 4.18 7.314 9.404 14.629 14.106 20.898 7.314 9.927 15.151 18.808 24.033 27.167 7.314 7.314 15.673 14.106 24.033 20.898 41.273 30.825 90.906 47.02 142.106 47.02s100.833-16.196 142.106-47.02c8.359-6.269 16.718-13.584 24.033-20.898 8.359-8.359 16.718-17.241 24.033-27.167 5.224-6.792 9.927-13.584 14.106-20.898 2.611-3.135 3.133-6.793 1.566-9.927z"
                      data-original="#000000"
                    ></path>
                  </svg>
                  <div className="efi-profile-edit">
                    <Form.Control type="file" placeholder="name@example.com" />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path d="M4.76 18.81h.11l2.93-.27c.41-.04.79-.22 1.08-.51L19.94 6.97c.52-.52.81-1.21.81-1.94s-.29-1.42-.81-1.94l-.71-.71c-1.04-1.04-2.85-1.04-3.89 0l-1.41 1.41-9.64 9.64c-.29.29-.47.67-.5 1.08l-.27 2.93c-.03.37.1.73.36 1 .24.24.55.37.88.37zM17.29 3.07c.32 0 .64.12.88.37l.71.71a1.234 1.234 0 010 1.76l-.88.88-2.47-2.47.88-.88c.24-.24.56-.37.88-.37zM5.28 14.65c0-.06.03-.11.07-.15l9.11-9.12 2.47 2.47-9.11 9.11s-.1.07-.15.07l-2.63.24.24-2.63zM22.75 22c0 .41-.34.75-.75.75H2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h20c.41 0 .75.34.75.75z"></path>
                    </svg>
                  </div>
                </div>
                <h3>
                  Thomas Klein
                </h3>
                <div className="efi-profile-kyc-status-card completed ">
                  <span> Kyc Verfied  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 64 64"
                  >
                    <g data-name="Approve Glow">
                      <path
                        fill="#47b574"
                        d="M32 10a1 1 0 01-1-1V5a1 1 0 012 0v4a1 1 0 01-1 1zm-11.499 3.081a1 1 0 01-.867-.5l-2-3.464a1 1 0 011.732-1l2 3.464a1 1 0 01-.865 1.5zM12.08 21.5a.994.994 0 01-.499-.134l-3.464-2a1 1 0 011-1.732l3.464 2a1 1 0 01-.5 1.866zM9 33H5a1 1 0 010-2h4a1 1 0 010 2zm-.382 13.5a1 1 0 01-.5-1.866l3.463-2a1 1 0 111 1.732l-3.464 2a.994.994 0 01-.499.134zm9.881 9.883a1 1 0 01-.865-1.5l2-3.464a1 1 0 111.732 1l-2 3.464a1 1 0 01-.867.5zM32 60a1 1 0 01-1-1v-4a1 1 0 012 0v4a1 1 0 01-1 1zm13.501-3.617a1 1 0 01-.867-.5l-2-3.464a1 1 0 111.732-1l2 3.464a1 1 0 01-.865 1.5zm9.881-9.883a.994.994 0 01-.5-.134l-3.463-2a1 1 0 111-1.732l3.464 2a1 1 0 01-.501 1.866zM59 33h-4a1 1 0 010-2h4a1 1 0 010 2zm-7.08-11.5a1 1 0 01-.501-1.866l3.464-2a1 1 0 011 1.732l-3.464 2a.994.994 0 01-.5.134zm-8.421-8.419a1 1 0 01-.865-1.5l2-3.464a1 1 0 111.732 1l-2 3.464a1 1 0 01-.867.5z"
                        data-original="#54e346"
                      ></path>
                      <path
                        fill="#52834b"
                        d="M45.43 25.2a3.538 3.538 0 01-1.04 2.5L30.72 41.37a3.114 3.114 0 01-4.41.01l-1-1-5.74-5.73a3.521 3.521 0 015-4.96l2.97 2.96 1 1 10.9-10.9a3.51 3.51 0 014.42-.47 3.379 3.379 0 01.55.45 3.474 3.474 0 011.02 2.47z"
                        data-original="#54b646"
                      ></path>
                      <path
                        fill="#47b574"
                        d="M44.43 24.2a3.538 3.538 0 01-1.04 2.5L29.72 40.37a3.114 3.114 0 01-4.41.01l-5.74-5.73a3.521 3.521 0 015-4.96l2.97 2.96 1 1 10.9-10.9a3.51 3.51 0 014.42-.47 3.478 3.478 0 01.57 1.92z"
                        data-original="#54e346"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div className="efi-profile-widget-action-card mt-3">
                <div className="efi-profile-widget-card">
                  <div className="efi-profile-widget-card-info">
                    <h4>   <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <g fill="#000">
                        <path d="M12 12.75c-3.17 0-5.75-2.58-5.75-5.75S8.83 1.25 12 1.25 17.75 3.83 17.75 7s-2.58 5.75-5.75 5.75zm0-10C9.66 2.75 7.75 4.66 7.75 7s1.91 4.25 4.25 4.25S16.25 9.34 16.25 7 14.34 2.75 12 2.75zM20.59 22.75c-.41 0-.75-.34-.75-.75 0-3.45-3.52-6.25-7.84-6.25S4.16 18.55 4.16 22c0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-4.27 4.19-7.75 9.34-7.75 5.15 0 9.34 3.48 9.34 7.75 0 .41-.34.75-.75.75z"></path>
                      </g>
                    </svg> {t("username")}</h4>
                    <p>{profile.data.name}</p>
                  </div>
                  <div className="efi-profile-widget-card-action">
                    <Button
                      className="efi-profile-btn"
                      onClick={() => setChangeUserName(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <g fillRule="evenodd" clipRule="evenodd">
                          <path
                            fill="#999"
                            d="M20.75 21.94h-7.252a.75.75 0 010-1.5h7.253a.75.75 0 010 1.5zM16.223 11.685a.753.753 0 01-.457-.155l-5.452-4.188a.751.751 0 01.914-1.19l5.453 4.188a.75.75 0 01-.458 1.345z"
                          ></path>
                          <path
                            fill="#000"
                            d="M13.11 5.017L3.697 16.792c-.171.214-.234.49-.171.755l.68 2.885 3.04-.038a.949.949 0 00.733-.352c3.216-4.025 9.35-11.7 9.523-11.924a1.36 1.36 0 00.142-1.004 1.411 1.411 0 00-.652-.887 328.898 328.898 0 01-1.808-1.398 1.497 1.497 0 00-2.073.188zM3.614 21.94a.75.75 0 01-.73-.577l-.82-3.471a2.371 2.371 0 01.46-2.037l9.42-11.782c.004-.004.007-.01.011-.013 1.033-1.235 2.901-1.417 4.161-.406l1.723 1.339c.608.362 1.083 1.009 1.263 1.775.18.758.05 1.54-.368 2.2-.03.05-.058.092-9.585 12.012a2.447 2.447 0 01-1.886.914l-3.64.046z"
                          ></path>
                        </g>
                      </svg>
                      {t("edit")}
                    </Button>
                  </div>
                </div>
                <div className="efi-profile-widget-card">
                  <div className="efi-profile-widget-card-info">
                    <h4> <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 19"
                    >
                      <path
                        fill="#020E30"
                        d="M16.625.313H4.375a4.54 4.54 0 00-3.092 1.2C.463 2.278 0 3.318 0 4.402v9.819c.001 1.084.463 2.124 1.283 2.89a4.54 4.54 0 003.092 1.2h12.25a4.54 4.54 0 003.092-1.2c.82-.766 1.282-1.806 1.283-2.89V4.403c-.001-1.084-.463-2.124-1.283-2.89a4.54 4.54 0 00-3.092-1.2zM4.375 1.948h12.25a2.755 2.755 0 011.47.424c.433.275.768.665.963 1.12l-6.701 6.266c-.493.46-1.161.717-1.857.717a2.727 2.727 0 01-1.857-.717l-6.7-6.266c.194-.455.53-.845.963-1.12a2.755 2.755 0 011.469-.424zm12.25 14.727H4.375a2.72 2.72 0 01-1.856-.719 2.376 2.376 0 01-.769-1.735V5.63l5.656 5.285a4.54 4.54 0 003.094 1.196 4.54 4.54 0 003.094-1.196l5.656-5.285v8.59c0 .652-.277 1.276-.769 1.736a2.72 2.72 0 01-1.856.72z"
                      ></path>
                    </svg>{t("change_email_address")}</h4>
                    <p>{profile.data.email}</p>
                  </div>
                  <div className="efi-profile-widget-card-action">
                    <Button
                      className="efi-profile-btn"
                      onClick={() => setChangeEmailAddress(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <g fillRule="evenodd" clipRule="evenodd">
                          <path
                            fill="#999"
                            d="M20.75 21.94h-7.252a.75.75 0 010-1.5h7.253a.75.75 0 010 1.5zM16.223 11.685a.753.753 0 01-.457-.155l-5.452-4.188a.751.751 0 01.914-1.19l5.453 4.188a.75.75 0 01-.458 1.345z"
                          ></path>
                          <path
                            fill="#000"
                            d="M13.11 5.017L3.697 16.792c-.171.214-.234.49-.171.755l.68 2.885 3.04-.038a.949.949 0 00.733-.352c3.216-4.025 9.35-11.7 9.523-11.924a1.36 1.36 0 00.142-1.004 1.411 1.411 0 00-.652-.887 328.898 328.898 0 01-1.808-1.398 1.497 1.497 0 00-2.073.188zM3.614 21.94a.75.75 0 01-.73-.577l-.82-3.471a2.371 2.371 0 01.46-2.037l9.42-11.782c.004-.004.007-.01.011-.013 1.033-1.235 2.901-1.417 4.161-.406l1.723 1.339c.608.362 1.083 1.009 1.263 1.775.18.758.05 1.54-.368 2.2-.03.05-.058.092-9.585 12.012a2.447 2.447 0 01-1.886.914l-3.64.046z"
                          ></path>
                        </g>
                      </svg>
                      {t("edit")}
                    </Button>
                  </div>
                </div>
                <div className="efi-profile-widget-card">
                  <div className="efi-profile-widget-card-info">
                    <h4>      <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 32 32"
                    >
                      <path
                        d="M30.035 22.594c-.053-.044-6.042-4.33-7.667-4.049-.781.138-1.228.67-2.123 1.737a30.54 30.54 0 01-.759.876 12.458 12.458 0 01-1.651-.672 13.7 13.7 0 01-6.321-6.321 12.458 12.458 0 01-.672-1.651c.294-.269.706-.616.882-.764 1.061-.89 1.593-1.337 1.731-2.119.283-1.619-4.005-7.613-4.049-7.667A2.289 2.289 0 007.7 1C5.962 1 1 7.436 1 8.521c0 .063.091 6.467 7.988 14.5C17.012 30.909 23.416 31 23.479 31 24.564 31 31 26.038 31 24.3a2.287 2.287 0 00-.965-1.706zm-6.666 6.4c-.874-.072-6.248-.781-12.967-7.382C3.767 14.857 3.076 9.468 3.007 8.633a27.054 27.054 0 014.706-5.561c.04.04.093.1.161.178a35.391 35.391 0 013.574 6.063 11.886 11.886 0 01-1.016.911 10.033 10.033 0 00-1.512 1.422l-.243.34.072.411a11.418 11.418 0 00.965 2.641 15.71 15.71 0 007.248 7.247 11.389 11.389 0 002.641.966l.411.072.34-.243a10.117 10.117 0 001.428-1.518c.313-.374.732-.873.89-1.014a35.163 35.163 0 016.078 3.578c.083.07.141.124.18.159a27.031 27.031 0 01-5.561 4.707z"
                        data-name="Layer 3"
                      ></path>
                    </svg>Phone Number</h4>
                    <p>+{profile.data.country_code} {profile.data.mobile}</p>
                  </div>
                  <div className="efi-profile-widget-card-action">
                    <Button
                      className="efi-profile-btn"
                      onClick={() => setchangeMobileNumber(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <g fillRule="evenodd" clipRule="evenodd">
                          <path
                            fill="#999"
                            d="M20.75 21.94h-7.252a.75.75 0 010-1.5h7.253a.75.75 0 010 1.5zM16.223 11.685a.753.753 0 01-.457-.155l-5.452-4.188a.751.751 0 01.914-1.19l5.453 4.188a.75.75 0 01-.458 1.345z"
                          ></path>
                          <path
                            fill="#000"
                            d="M13.11 5.017L3.697 16.792c-.171.214-.234.49-.171.755l.68 2.885 3.04-.038a.949.949 0 00.733-.352c3.216-4.025 9.35-11.7 9.523-11.924a1.36 1.36 0 00.142-1.004 1.411 1.411 0 00-.652-.887 328.898 328.898 0 01-1.808-1.398 1.497 1.497 0 00-2.073.188zM3.614 21.94a.75.75 0 01-.73-.577l-.82-3.471a2.371 2.371 0 01.46-2.037l9.42-11.782c.004-.004.007-.01.011-.013 1.033-1.235 2.901-1.417 4.161-.406l1.723 1.339c.608.362 1.083 1.009 1.263 1.775.18.758.05 1.54-.368 2.2-.03.05-.058.092-9.585 12.012a2.447 2.447 0 01-1.886.914l-3.64.046z"
                          ></path>
                        </g>
                      </svg>
                      {t("edit")}
                    </Button>
                  </div>
                </div>
                <div className="efi-profile-widget-card">
                  <div className="efi-profile-widget-card-info">
                    <h4>       <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 32 32"
                    >
                      <g data-name="12-Lock">
                        <path d="M23 12V8.71A6.72 6.72 0 0016.29 2h-.58A6.72 6.72 0 009 8.71V12a3 3 0 00-3 3v9.3a5.71 5.71 0 005.7 5.7h8.6a5.71 5.71 0 005.7-5.7V15a3 3 0 00-3-3zM11 8.71A4.71 4.71 0 0115.71 4h.58A4.71 4.71 0 0121 8.71V12H11zM24 24.3a3.7 3.7 0 01-3.7 3.7h-8.6A3.7 3.7 0 018 24.3V15a1 1 0 011-1h14a1 1 0 011 1z"></path>
                        <path d="M17 20.72V24a1 1 0 01-2 0v-3.28a2 2 0 112 0z"></path>
                      </g>
                    </svg>{t("change_password")}</h4>
                    <p>*******************</p>
                  </div>
                  <div className="efi-profile-widget-card-action">
                    <Button
                      className="efi-profile-btn"
                      onClick={() => setChangePassword(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <g fillRule="evenodd" clipRule="evenodd">
                          <path
                            fill="#999"
                            d="M20.75 21.94h-7.252a.75.75 0 010-1.5h7.253a.75.75 0 010 1.5zM16.223 11.685a.753.753 0 01-.457-.155l-5.452-4.188a.751.751 0 01.914-1.19l5.453 4.188a.75.75 0 01-.458 1.345z"
                          ></path>
                          <path
                            fill="#000"
                            d="M13.11 5.017L3.697 16.792c-.171.214-.234.49-.171.755l.68 2.885 3.04-.038a.949.949 0 00.733-.352c3.216-4.025 9.35-11.7 9.523-11.924a1.36 1.36 0 00.142-1.004 1.411 1.411 0 00-.652-.887 328.898 328.898 0 01-1.808-1.398 1.497 1.497 0 00-2.073.188zM3.614 21.94a.75.75 0 01-.73-.577l-.82-3.471a2.371 2.371 0 01.46-2.037l9.42-11.782c.004-.004.007-.01.011-.013 1.033-1.235 2.901-1.417 4.161-.406l1.723 1.339c.608.362 1.083 1.009 1.263 1.775.18.758.05 1.54-.368 2.2-.03.05-.058.092-9.585 12.012a2.447 2.447 0 01-1.886.914l-3.64.046z"
                          ></path>
                        </g>
                      </svg>
                      {t("edit")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={6}>
            <div className="efi-profile-widget-action-card">
              <div className="efi-profile-widget-card efi-border-profile">
                <div className="efi-profile-icon-card">
                  <div className="efi-profile-icon-avater">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 32 32"
                    >
                      <g data-name="Layer 2">
                        <path
                          fill="#232323"
                          d="M7.575 29.917H3.333a.75.75 0 01-.75-.75v-4.242a.75.75 0 01.22-.53l9.861-9.862a.289.289 0 00.045-.288 8.748 8.748 0 0113.728-9.621 8.749 8.749 0 01-8.185 15.166.286.286 0 00-.283.045l-2.085 2.084a.75.75 0 01-.53.22h-2.08v2.079a.75.75 0 01-.219.53l-.707.707a.75.75 0 01-.53.22H9.74v2.078a.75.75 0 01-.22.53l-1.415 1.414a.75.75 0 01-.53.22zm-3.492-1.5h3.182l.975-.975v-2.517a.75.75 0 01.75-.75h2.517l.268-.268V21.39a.75.75 0 01.75-.75h2.518l1.865-1.865a1.762 1.762 0 011.817-.408 7.248 7.248 0 006.777-12.568 7.247 7.247 0 00-11.369 7.975 1.772 1.772 0 01-.408 1.82l-9.642 9.642z"
                          data-original="#232323"
                        ></path>
                        <path
                          fill="#5a36a8"
                          d="M21.5 13.748a3.248 3.248 0 112.298-.95 3.239 3.239 0 01-2.298.95zm0-4.997a1.749 1.749 0 101.237.512A1.745 1.745 0 0021.5 8.75z"
                          data-original="#7fbde7"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className="efi-profile-widget-card-info">
                    <h4>{t("api_key")}</h4>
                    <p>***************</p>
                  </div>
                </div>
                <div className="efi-profile-widget-card-action">
                  <Button
                    className="efi-profile-btn"
                    onClick={() => setApiKeyModal(1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                    >
                      <g data-name="44 Visibility">
                        <path d="M16 21c-6.6 0-6.6-10 0-10 6.525 0 6.807 10 0 10zm0-8c-4 0-4 6 0 6s4-6 0-6z"></path>
                        <path d="M16 25c-4.949 0-9.688-2.629-13-7.214a3.043 3.043 0 010-3.572C6.312 9.629 11.051 7 16 7s9.688 2.629 13 7.214a3.043 3.043 0 010 3.572C25.688 22.371 20.949 25 16 25zM4.617 16.614C7.595 20.732 11.637 23 16 23s8.405-2.268 11.383-6.386a1.047 1.047 0 000-1.229C24.405 11.268 20.363 9 16 9s-8.405 2.268-11.383 6.386a1.047 1.047 0 000 1.229z"></path>
                      </g>
                    </svg>
                    {t("view")}
                  </Button>
                </div>
              </div>
              <div className="efi-profile-widget-card efi-border-profile">
                <div className="efi-profile-icon-card">
                  <div className="efi-profile-icon-avater">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 32 32"
                    >
                      <g data-name="Layer 2">
                        <path
                          fill="#232323"
                          d="M7.575 29.917H3.333a.75.75 0 01-.75-.75v-4.242a.75.75 0 01.22-.53l9.861-9.862a.289.289 0 00.045-.288 8.748 8.748 0 0113.728-9.621 8.749 8.749 0 01-8.185 15.166.286.286 0 00-.283.045l-2.085 2.084a.75.75 0 01-.53.22h-2.08v2.079a.75.75 0 01-.219.53l-.707.707a.75.75 0 01-.53.22H9.74v2.078a.75.75 0 01-.22.53l-1.415 1.414a.75.75 0 01-.53.22zm-3.492-1.5h3.182l.975-.975v-2.517a.75.75 0 01.75-.75h2.517l.268-.268V21.39a.75.75 0 01.75-.75h2.518l1.865-1.865a1.762 1.762 0 011.817-.408 7.248 7.248 0 006.777-12.568 7.247 7.247 0 00-11.369 7.975 1.772 1.772 0 01-.408 1.82l-9.642 9.642z"
                          data-original="#232323"
                        ></path>
                        <path
                          fill="#5a36a8"
                          d="M21.5 13.748a3.248 3.248 0 112.298-.95 3.239 3.239 0 01-2.298.95zm0-4.997a1.749 1.749 0 101.237.512A1.745 1.745 0 0021.5 8.75z"
                          data-original="#7fbde7"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className="efi-profile-widget-card-info">
                    <h4>{t("salt_key")}</h4>
                    <p>***************</p>
                  </div>
                </div>
                <div className="efi-profile-widget-card-action">
                  <Button
                    className="efi-profile-btn"
                    onClick={() => setApiKeyModal(2)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                    >
                      <g data-name="44 Visibility">
                        <path d="M16 21c-6.6 0-6.6-10 0-10 6.525 0 6.807 10 0 10zm0-8c-4 0-4 6 0 6s4-6 0-6z"></path>
                        <path d="M16 25c-4.949 0-9.688-2.629-13-7.214a3.043 3.043 0 010-3.572C6.312 9.629 11.051 7 16 7s9.688 2.629 13 7.214a3.043 3.043 0 010 3.572C25.688 22.371 20.949 25 16 25zM4.617 16.614C7.595 20.732 11.637 23 16 23s8.405-2.268 11.383-6.386a1.047 1.047 0 000-1.229C24.405 11.268 20.363 9 16 9s-8.405 2.268-11.383 6.386a1.047 1.047 0 000 1.229z"></path>
                      </g>
                    </svg>
                    {t("view")}
                  </Button>
                </div>
              </div>
              <div className="efi-profile-widget-card efi-border-profile">
                <div className="efi-profile-icon-card">
                  <div className="efi-profile-icon-avater">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 24 24"
                    >
                      <g data-name="Layer 2">
                        <path
                          fill="#5a36a8"
                          d="M15.846 22.75H8.154a.75.75 0 01-.75-.75v-3.154a4.596 4.596 0 019.192 0V22a.75.75 0 01-.75.75zm-6.942-1.5h6.192v-2.404a3.096 3.096 0 00-6.192 0z"
                          data-original="#7fbde7"
                        ></path>
                        <g fill="#232323">
                          <path
                            d="M19 22.75H5A2.753 2.753 0 012.25 20v-8.26a.75.75 0 011.5 0V20A1.251 1.251 0 005 21.25h14A1.251 1.251 0 0020.25 20v-8.26a.75.75 0 111.5 0V20A2.753 2.753 0 0119 22.75z"
                            data-original="#232323"
                          ></path>
                          <path
                            d="M16 12.75a2.742 2.742 0 01-2-.864 2.746 2.746 0 01-4 0 2.746 2.746 0 01-3.998.002c-.067.071-.14.14-.215.205a2.77 2.77 0 01-2.236.622A2.823 2.823 0 011.25 9.902V8a.75.75 0 01.08-.335l2.446-4.894a2.734 2.734 0 012.46-1.521h11.528a2.734 2.734 0 012.46 1.52l2.447 4.895A.75.75 0 0122.75 8v1.902a2.821 2.821 0 01-2.3 2.812 2.762 2.762 0 01-2.237-.621 3.057 3.057 0 01-.215-.205A2.745 2.745 0 0116 12.75zM2.75 8.177v1.725a1.313 1.313 0 001.036 1.331 1.266 1.266 0 001.026-.28A1.25 1.25 0 005.25 10a.75.75 0 011.5 0 1.25 1.25 0 002.5 0 .75.75 0 011.5 0 1.25 1.25 0 002.5 0 .75.75 0 011.5 0 1.25 1.25 0 002.5 0 .75.75 0 011.5 0 1.248 1.248 0 001.465 1.232 1.312 1.312 0 001.035-1.33V8.177L18.882 3.44a1.243 1.243 0 00-1.118-.69H6.236a1.243 1.243 0 00-1.118.69z"
                            data-original="#232323"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="efi-profile-widget-card-info">
                    <h4>  {t("merchant_id")}</h4>
                    <p className="widget-merchant-id" >EFI00{zeroPad(profile.data.user_id, 3)}</p>
                  </div>
                </div>
                <div className="efi-profile-widget-card-action">
                  <CopyToClipboard
                    text={"EFI00" + zeroPad(profile.data.user_id, 3)}
                    onCopy={() => getSuccessNotificationMessage(t("merchant_id_copied_to_clipboard"))}>
                    <Button className="efi-profile-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 6.35 6.35"
                      >
                        <path
                          fill="#878e96"
                          fillRule="evenodd"
                          d="M1.06.265a.8.8 0 00-.795.795v3.71a.265.265 0 10.53 0V1.06A.26.26 0 011.06.795h3.71a.265.265 0 100-.53zm1.06 1.06a.8.8 0 00-.796.796V5.3a.8.8 0 00.797.796h3.178a.8.8 0 00.797-.796V2.121a.8.8 0 00-.797-.796z"
                          data-original="#000000"
                          paintOrder="stroke fill markers"
                        ></path>
                      </svg>
                      <span>{t("copy")}</span>
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="efi-profile-widget-card efi-border-profile">
                <div className="efi-profile-icon-card">
                  <div className="efi-profile-icon-avater">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 24 24"
                    >
                      <g fillRule="evenodd" clipRule="evenodd">
                        <path
                          fill="#5a36a8"
                          d="M9 12.12a.75.75 0 01.75-.75h12.041a.75.75 0 010 1.5H9.75a.75.75 0 01-.75-.75z"
                          data-original="#999999"
                        ></path>
                        <path
                          fill="#5a36a8"
                          d="M18.333 8.675a.75.75 0 011.06-.002l2.928 2.916a.75.75 0 010 1.063l-2.928 2.916a.75.75 0 11-1.058-1.063l2.394-2.384-2.394-2.385a.75.75 0 01-.002-1.06z"
                          data-original="#999999"
                        ></path>
                        <path
                          d="M14.288 4.45c.777.576 1.165 1.516 1.325 3.249a.75.75 0 101.494-.138c-.17-1.848-.617-3.347-1.926-4.316C13.927 2.315 12.037 2 9.36 2c-3.55 0-5.733.556-6.853 2.38-.533.869-.77 1.945-.884 3.177-.114 1.228-.114 2.702-.114 4.411v.063c0 1.71 0 3.184.114 4.411.115 1.233.35 2.309.884 3.178C3.627 21.443 5.81 22 9.36 22c2.677 0 4.567-.316 5.821-1.245 1.309-.97 1.755-2.469 1.926-4.316a.75.75 0 00-1.494-.138c-.16 1.732-.548 2.673-1.325 3.249-.83.615-2.276.95-4.928.95-3.551 0-4.92-.599-5.574-1.665-.354-.576-.562-1.379-.67-2.532-.106-1.15-.107-2.556-.107-4.303s0-3.154.108-4.304c.107-1.153.315-1.955.668-2.531C4.441 4.098 5.81 3.5 9.36 3.5c2.652 0 4.098.334 4.928.95z"
                          data-original="#000000"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className="efi-profile-widget-card-info">
                    <h4>{t("logout_of_all_device")}</h4>
                    <p>{t("if_you_notice_any_para")}</p>
                  </div>
                </div>
                <div className="efi-profile-widget-card-action">
                  <Button
                    className="efi-profile-btn"
                    onClick={logout}
                  >
                    {t("logout")}
                  </Button>
                </div>
              </div>
              <div className="efi-profile-widget-card efi-border-profile">
                <div className="efi-profile-icon-card">
                  <div className="efi-profile-icon-avater">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M11.17 2.25a5.25 5.25 0 105.25 5.25 5.26 5.26 0 00-5.25-5.25zm0 9a3.75 3.75 0 113.75-3.75 3.76 3.76 0 01-3.75 3.75zM12.8 21a.75.75 0 01-.75.75H4.83a2.76 2.76 0 01-2.65-3.51 6.92 6.92 0 016.62-5h2.61a.75.75 0 010 1.5H8.8a5.4 5.4 0 00-5.17 3.91 1.25 1.25 0 001.2 1.59h7.22a.75.75 0 01.75.76z"
                        data-original="#000000"
                      ></path>
                      <path
                        fill="#5a36a8"
                        d="M19.42 17a.75.75 0 01-.75.75h-3a.75.75 0 010-1.5h3a.75.75 0 01.75.75zm2.5 0a4.75 4.75 0 11-4.75-4.75A4.75 4.75 0 0121.92 17zm-1.5 0a3.25 3.25 0 10-3.25 3.25A3.25 3.25 0 0020.42 17z"
                        data-original="#f25220"
                      ></path>
                    </svg>
                  </div>
                  <div className="efi-profile-widget-card-info">
                    <h4>{t("delete_your_account")}</h4>
                    <p>{t("if_you_want_to_stop_using_inefipay")}</p>
                  </div>
                </div>
                <div className="efi-profile-widget-card-action">
                  <Button
                    className="efi-profile-btn"
                    onClick={() => setDeleteAccount(true)}
                  >

                    {t("delete")}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div> */}
      {changeUserName && (
        <ChangeUserNameModal
          profile={profile}
          changeUserName={changeUserName}
          closeChangeUserNameModal={closeChangeUserNameModal}
          setChangeUserName={setChangeUserName}
        />
      )}
      {changePassword && (
        <ChangePasswordModal
          profile={profile}
          changePassword={changePassword}
          closeChangePasswordModal={closeChangePasswordModal}
          setChangePassword={setChangePassword}
        />
      )}
      {changeMobileNumber && (
        <ChangeMobileNumberModal
          profile={profile}
          changeMobileNumber={changeMobileNumber}
          closeChangeMobileNumberModal={closeChangeMobileNumberModal}
          setchangeMobileNumber={setchangeMobileNumber}
        />
      )}

      {changeEmailAddress && (
        <ChangeEmailAddressModal
          profile={profile}
          setNewEmail={setNewEmail}
          changeEmailAddress={changeEmailAddress}
          closeChangeEmailAddressModal={closeChangeEmailAddressModal}
          setChangeEmailAddress={setChangeEmailAddress}
        />
      )}
      {deleteAccount && (
        <DeleteAccountModal
          deleteAccount={deleteAccount}
          closeDeleteAccountModal={closeDeleteAccountModal}
          setDeleteAccount={setDeleteAccount}
        />
      )}
      {apiKeyModal && (
        <ApiKeyModal
          apiKeyModal={apiKeyModal}
          closeApiKeyModal={closeApiKeyModal}
          setApiKeyModal={setApiKeyModal}
        />
      )}
      {verifyKYC && (
        <VerifyKYCModal
          verifyKYC={verifyKYC}
          closeVerifyKYCModal={closeVerifyKYCModal}
          setVerifyKYC={setVerifyKYC}
        />
      )}
      {showVerify && (
        <OTPVerificationModal
          forgotPassword={2}
          showVerify={showVerify}
          closeShowVerify={() => setShowVerify(false)}
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />

  );
};

export default AccountIndex;
