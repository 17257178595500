import React from "react";
import { Image, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";

const PaymentStepFour = (props) => {

  const transactionView = useSelector((state) => state.payment.transactionView);

  return (
    <>
      <div className="step-payment-one">
        <div className="payment-details-wrapped">
          <div className="payment-details-info">
            <div className="payment-details-avater-frame text-center">
              <CustomLazyLoad
                className="payment-avater-frame"
                src={transactionView.data.payment_page.transaction_initiated_image || (window.location.origin + "/img/widgets/success-avater.png")}
                type="image/png"
              />
            </div>
            <div className="payment-details-amount">
              <h2 className="mb-2">Payment Success</h2>
              <div className="dashed-line"></div>
              <h3>
                Payment Method <span> {props.paymentType} </span>
              </h3>
              {/* {props.transaction.data ? <p>{props.transaction.created_utc}</p> : null} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentStepFour;

export const PaymentInitiated = (props) => {
    return (
      <>
        <div className="step-payment-one">
          <div className="payment-details-wrapped">
            <div className="payment-details-info">
              <div className="payment-details-avater-frame text-center">
                <CustomLazyLoad
                  className="payment-avater-frame"
                  src={window.location.origin + "/img/widgets/initiated.png"}
                  type="image/png"
                />
              </div>
              <div className="payment-details-amount">
                <h2 className="mb-2">Payment Processing</h2>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
