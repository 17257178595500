import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { Form, InputGroup, Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loginStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import Helmet from "react-helmet";
import configuration from "react-global-configuration";
import OTPVerificationModal from "./OTPVerificationModal";
import { Image } from "react-bootstrap";

const LoginIndex = () => {
  const t = useTranslation("login");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.admin.login);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const emailVerify = useSelector(state => state.admin.emailVerify);
  const [showPassword, setShowPassword] = useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleLogin = (values) => {
    dispatch(loginStart(values));
  };

  useEffect(() => {
    if (!skipRender && !login.buttonDisable && Object.keys(login.data).length > 0) {
      if (login.data.email_status) {
        navigate("/")
      } else {
        setShowVerify(login.data)
      }
    }
    setSkipRender(false);
  }, [login]);

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      navigate('/')
    }
    setSkipRender(false);
  }, [emailVerify]);


  return (
    <>
      <div className="efi-auth-sec">
        <div className="container">
          <div className="header-logo">
            <div className="efi-logo-sec">
              <a href="https://efimarkets.com">
                <Image
                className="logo logo-filter"
                src="/img/logo.png"
                // src={configuration.get("configData.site_logo")}
              />
              </a>
            </div>
          </div>
        </div>
        <div className="row align-items-center g-0">
          <div className="col-md-12 col-lg-5 col-xl-4">
            <div className="auth-efi-background-further">
              <div className="efi-widget-auth-info-frame">
                <h3>New here?</h3>
                <p>Sign up and discover a great amount of new opportunities</p>
                <div className="efi-auth-widget-action">
                <Link  className="efi-outline-btn" to="/register">{t("register")}</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-7 col-xl-8">
            <div className="auth-efi-background-form">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                  <div className="efi-auth-box">
                    <div className="efi-auth-title-sec text-center ">Welcome back,</div>
                    <p className="text-center auth-text-p">If you already have an account just sign in</p>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                        remember: false,
                      }}
                      validationSchema={loginSchema}
                      onSubmit={handleLogin}
                    >
                      {({ setFieldValue, values }) => (
                        <FORM className="efi-auth-form">
                          <div className="mb-3">
                            <label for="exampleInputEmail1" className="form-label">
                              {t("email.label")}
                            </label>
                            <Field
                              className="form-control"
                              placeholder={t("email.placeholder")}
                              type="email"
                              autoFocus={true}
                              name="email"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="errorMsg"
                            />
                          </div>
                          <div className="mb-3">
                            <Form.Label htmlFor="basic-url"> {t("password.label")}</Form.Label>
                            <InputGroup>
                              <Field
                                className="form-control"
                                placeholder={t("password.placeholder")}
                                type={showPassword ? "text" : "password"}
                                name="password"
                              />
                              <InputGroup.Text id="basic-addon3">
                                <Button className="hide-btn" onClick={() => setShowPassword(!showPassword)}>
                                  {!showPassword ? <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" fill="none" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg> :
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>}
                                </Button>
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="errorMsg"
                            />
                          </div>
                          <div className="forgot-password-link-flex">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={values.remember}
                                onChange={() =>
                                  setFieldValue("remember", !values.remember)
                                }
                                id="flexCheckDefault"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                                {t("remember")}
                              </label>
                            </div>
                            <Link
                              to="/forgot-password"
                              className="forgot-password-link"
                            >
                              {t("forgot")}
                            </Link>
                          </div>
                          <div className="efi-ath-btn-sec">
                            <button
                              className="efi-auth-btn"
                              disabled={login.buttonDisable}
                            >
                              {!login.buttonDisable && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                                </svg>
                              )}
                              {login.buttonDisable ? (
                                <ButtonLoader varient="black" />
                              ) : (
                                t("login_btn.text")
                              )}
                            </button>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVerify ?
        <OTPVerificationModal showVerify={showVerify} closeShowVerify={() => setShowVerify(false)} setShowVerify={setShowVerify} />
        : null}
    </>
  );
};

export default LoginIndex;
