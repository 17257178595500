import React, { useState, useEffect } from "react";
import PaymentWidgetDetails from "./PaymentWidgetDetails";
import PaymentWidgetPayMethod from "./PaymentWidgetPayMethod";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { transactionViewStart } from "../../../store/slices/PaymentSlice";
import CryptoJS from "crypto-js";
import PageLoaderFull from "../../Helper/PageLoaderFull";
import SomethingWentWrong from "../../Helper/SomethingWentWrong";
import PageLoader from "../../Helper/PageLoader";
import {
  Container,
  Row,
  Col,
  Image
} from "react-bootstrap";
import configuration from "react-global-configuration";

const PaymentWidgetIndex = (props) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const transactionView = useSelector((state) => state.payment.transactionView);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (searchParams.get("token")) {
      let key =
        process.env.REACT_APP_TRANSACTION_DECRYPTION_KEY ||
        "1mYUZyXl4oTchbMk0mzj0RiNlSWi5ZMomSWDvmoXelA=";
      let encrypted_json = JSON.parse(atob(searchParams.get("token")));
      let decrypted = CryptoJS.AES.decrypt(
        encrypted_json.value,
        CryptoJS.enc.Base64.parse(key),
        {
          iv: CryptoJS.enc.Base64.parse(encrypted_json.iv),
        }
      );
      const transaction_decrypted = JSON.parse(
        decrypted.toString(CryptoJS.enc.Utf8)
      );
      sessionStorage.setItem("api_key", transaction_decrypted.api_key);
      // sessionStorage.setItem("payout_api_key", transaction_decrypted.payout_api_key)
      sessionStorage.setItem("salt_key", transaction_decrypted.salt_key);
      dispatch(
        transactionViewStart({
          order_id: transaction_decrypted.order_id,
        })
      );
    }
  }, []);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    if (!skipRender && !transactionView.loading && Object.keys(transactionView.data).length > 0 && Object.keys(transactionView.data.payment_page).length > 0) {
      document.documentElement.style.setProperty('--page-background-color', transactionView.data.payment_page.background);
      document.documentElement.style.setProperty('--card-top', transactionView.data.payment_page.card_header_background);
      document.documentElement.style.setProperty('--card-middle', transactionView.data.payment_page.card_body_background);
      document.documentElement.style.setProperty('--btn-primary-color', transactionView.data.payment_page.primary_btn_color);
      document.documentElement.style.setProperty('--btn-primary-color-hover', transactionView.data.payment_page.primary_btn_hover_color);
      document.documentElement.style.setProperty('--btn-secondary-color', transactionView.data.payment_page.secondary_btn_color);
      document.documentElement.style.setProperty('--btn-secondary-color-hover', transactionView.data.payment_page.secondary_btn_hover_color);
      document.documentElement.style.setProperty('--btn-cancel-confirm-color', transactionView.data.payment_page.cancel_confirm_btn_color);
      document.documentElement.style.setProperty('--btn-cancel-confirm-color-hover', transactionView.data.payment_page.cancel_confirm_btn_hover_color);
      document.documentElement.style.setProperty('--text-primary-color', transactionView.data.payment_page.primary_text_color);
      document.documentElement.style.setProperty('--text-secondary-color', transactionView.data.payment_page.secondary_text_color);
      document.documentElement.style.setProperty('--text-tertiary-color', transactionView.data.payment_page.tertiary_text_color);
      document.documentElement.style.setProperty('--text-cancel-color', transactionView.data.payment_page.cancel_text_color);
      document.documentElement.style.setProperty('--background-tertiary-color', transactionView.data.payment_page.tertiary_background_color);
      document.documentElement.style.setProperty('--icon-color', transactionView.data.payment_page.icon_color);
    }
    setSkipRender(false)
  }, [transactionView]);

  return transactionView.loading ? (
    <PageLoader />
  ) : (Object.keys(transactionView.data).length > 0 && Object.keys(transactionView.data.transaction).length > 0) ? (
    <>
      <div className="efi-widget-wrapped">
      <div className="container">
          <div className="header-logo">
            <div className="efi-logo-sec">
              <a href="https://efimarkets.com">
                <Image
                  className="logo logo-filter"
                  // src="/img/logo.png"
                  src={transactionView.data.payment_page.logo || configuration.get("configData.site_logo")}
              />
              </a>
            </div>
          </div>
        </div>
        <Container>
          <div className="efi-center-widget">
          <Row className="justify-content-center ">
            <Col lg={11}>
            <div className="efi-widget-frame">
              <PaymentWidgetDetails data={transactionView.data.transaction} />
              <PaymentWidgetPayMethod data={transactionView.data.transaction} />
            </div>
            </Col>
          </Row>
          </div>
          </Container>
      </div>
    </>
  ) : (
    <SomethingWentWrong />
  );
};

export default PaymentWidgetIndex;
