import React from "react";
import { Image, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";

const PaymentStepFive = (props) => {

  const transactionView = useSelector((state) => state.payment.transactionView);

  return (
    <>
      <div className="step-payment-one">
        <div className="payment-details-wrapped">
          <div className="payment-details-info">
            <div className="payment-details-avater-frame text-center">
              <CustomLazyLoad
                className="payment-avater-frame"
                src={transactionView.data.payment_page.transaction_failed_image || (window.location.origin + "/img/widgets/fail-avater.png")}
                type="image/png"
              />
            </div>
            <div className="payment-details-amount">
              <h2>Payment Failure</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentStepFive;
