import React, { useEffect, useState } from "react";
import { Table, Button, Dropdown, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { transactionDetailsStart } from "../../store/slices/PaymentSlice";
import PageLoader from "../Helper/PageLoader";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";

const TransactionDetails = (props) => {
  const t = useTranslation("payment.details")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const profile = useSelector((state) => state.admin.profile);
  const transactionDetails = useSelector((state) => state.payment.transactionDetails);
  const [deletePaymentModal, setDeletePaymentModal] = useState(false);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(transactionDetailsStart({ order_id: params.paymentId }));
  }, [profile]);

  const getStatusColor = (status) => {
    switch (status) {
      case "INITIATED":
        return "#5C9EEB";
      case "PROCESSING":
        return "#FFCE22";
      case "USER_PAID":
        return "#197E23";
      case "REJECTED":
        return "#818181";
      case "CANCELLED":
        return "#FF8A00";
      case "FAILED":
        return "#FF6A6A";
      case "TOKEN_RECEIVED":
        return "#5A36A8";
      default:
        return "#5C9EEB";
    }
  };

  function formatDate(dateString) {
    let timeZone = profile.data.timezone || "Asia/Kolkata";
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone,
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  }

  return (
    <>
      {/* <div className="payment-link-sec">
        <div className="payment-link-details-box">
          <div className="payment-link-details-header-sec">
            <Button
              className="back-btn"
              onClick={() => navigate("/transactions")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#979BA2"
                  d="M13.293 5.707L9.828 9.171a4 4 0 000 5.657l3.465 3.465c.63.63 1.707.184 1.707-.707V6.414c0-.89-1.077-1.337-1.707-.707z"
                ></path>
              </svg>
            </Button>
            <h3>{t("invoice_title")}</h3>
          </div>
          {transactionDetails.loading ? (
            <div className="payment-link-details-body-sec mt-3">
              <Skeleton height={40} />
              <Skeleton className="mt-3" height={120} />
              <div className="payment-link-details-copy-url-box mt-3">
                {[...Array(4)].map((i, key) => (
                  <Skeleton key={key} height={80} />
                ))}
              </div>
              <Row className="mt-3">
                <Col md={6}>
                  <Skeleton height={200} />
                </Col>
                <Col md={6}>
                  <Skeleton height={200} />
                </Col>
              </Row>
            </div>
          ) : Object.keys(transactionDetails.data).length > 0 ? (
            <div className="payment-link-details-body-sec">
              <div className="payment-link-details-top-sec">
                <div className="payment-link-details-card">
                  <div className="payment-link-details-left-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        fill="#111"
                        stroke="#111"
                        strokeWidth="0.5"
                        d="M10.045 12.668l-2.246 2.115c-1.37 1.254-3.524 1.114-4.813-.312-1.264-1.399-1.229-3.522.08-4.79L5.31 7.566a.687.687 0 00.016-.966.675.675 0 00-.958-.048L2.124 8.667c-1.89 1.78-1.939 4.819-.111 6.788 1.827 1.969 4.84 2.122 6.73.343l2.245-2.115a.688.688 0 000-.966.674.674 0 00-.941-.048h-.002 0zM15.686 2.548a4.882 4.882 0 00-3.341-1.58 4.556 4.556 0 00-3.387 1.233l-2.25 2.115a.687.687 0 00-.016.966c.26.28.69.302.958.05l2.248-2.115a3.257 3.257 0 012.423-.883c1.88.097 3.378 1.713 3.346 3.609a3.33 3.33 0 01-1.037 2.375l-2.246 2.115a.687.687 0 00-.016.966c.26.28.689.303.958.05l2.245-2.114c1.887-1.78 1.938-4.816.115-6.787z"
                      ></path>
                      <path
                        fill="#111"
                        stroke="#111"
                        strokeWidth="0.5"
                        d="M10.442 6.547l-4.13 3.89a.686.686 0 00-.033.964.676.676 0 00.974.05l4.13-3.89a.688.688 0 000-.966.675.675 0 00-.94-.048z"
                      ></path>
                    </svg>
                    <span>
                      {transactionDetails.data.transaction.receiver_wallet_address.slice(0, 10)}...
                      {transactionDetails.data.transaction.receiver_wallet_address.slice(-10)}
                    </span>
                  </div>
                  <div className="payment-link-details-right-sec">
                    <CopyToClipboard
                      text={transactionDetails.data.transaction.receiver_wallet_address
                      }
                      onCopy={() =>
                        getSuccessNotificationMessage(
                          t("url_copied")
                        )
                      }
                    >
                      <Button className="copy-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          fill="#5C9EEB"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                        </svg>
                        <span>{t("copy")}</span>
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>
                {transactionDetails.data.transaction.transaction_hash && (
                  <div className="payment-link-details-card">
                    <div className="payment-link-details-left-sec">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="none"
                        viewBox="0 0 18 18"
                      >
                        <path
                          fill="#111"
                          stroke="#111"
                          strokeWidth="0.5"
                          d="M10.045 12.668l-2.246 2.115c-1.37 1.254-3.524 1.114-4.813-.312-1.264-1.399-1.229-3.522.08-4.79L5.31 7.566a.687.687 0 00.016-.966.675.675 0 00-.958-.048L2.124 8.667c-1.89 1.78-1.939 4.819-.111 6.788 1.827 1.969 4.84 2.122 6.73.343l2.245-2.115a.688.688 0 000-.966.674.674 0 00-.941-.048h-.002 0zM15.686 2.548a4.882 4.882 0 00-3.341-1.58 4.556 4.556 0 00-3.387 1.233l-2.25 2.115a.687.687 0 00-.016.966c.26.28.69.302.958.05l2.248-2.115a3.257 3.257 0 012.423-.883c1.88.097 3.378 1.713 3.346 3.609a3.33 3.33 0 01-1.037 2.375l-2.246 2.115a.687.687 0 00-.016.966c.26.28.689.303.958.05l2.245-2.114c1.887-1.78 1.938-4.816.115-6.787z"
                        ></path>
                        <path
                          fill="#111"
                          stroke="#111"
                          strokeWidth="0.5"
                          d="M10.442 6.547l-4.13 3.89a.686.686 0 00-.033.964.676.676 0 00.974.05l4.13-3.89a.688.688 0 000-.966.675.675 0 00-.94-.048z"
                        ></path>
                      </svg>
                      <span>
                        {transactionDetails.data.transaction.transaction_hash.slice(0, 10)}...
                        {transactionDetails.data.transaction.transaction_hash.slice(-10)}
                      </span>
                    </div>
                    <div className="payment-link-details-right-sec">
                      <CopyToClipboard
                        text={transactionDetails.data.transaction.transaction_hash
                        }
                        onCopy={() =>
                          getSuccessNotificationMessage(
                            "Transaction hash copied"
                          )
                        }
                      >
                        <Button className="copy-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            fill="#5C9EEB"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                          </svg>
                          <span>{t("copy")}</span>
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
              </div>
              <div className="efi-payment-box">
                <Row>
                  <Col md={12}>
                    <div className="payment-link-details-copy-url-box">
                      {transactionDetails.data.transaction.callback_url && (
                        <div className="payment-link-details-copy-url-card bg-blue">
                          <div className="payment-link-details-copy-url-left-sec">
                            <div className="payment-link-details-copy-url-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 15 13"
                              >
                                <path
                                  stroke="#3584E1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M3.364 6.375L2.182 5.149 1 6.375M11.636 6.375l1.182 1.226 1.181-1.226"
                                ></path>
                                <path
                                  stroke="#3584E1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M12.82 7.6V6.376a5.658 5.658 0 00-.984-3.179 5.343 5.343 0 00-2.57-2.008 5.137 5.137 0 00-3.217-.104 5.309 5.309 0 00-2.685 1.84M2.182 5.15v1.225c.003 1.14.346 2.25.982 3.179a5.342 5.342 0 002.57 2.008 5.137 5.137 0 003.217.104 5.309 5.309 0 002.685-1.84"
                                ></path>
                              </svg>
                            </div>
                            <div className="payment-link-details-copy-url-info">
                              <h5>{t("callback_url")}</h5>
                              <p>
                                {transactionDetails.data.transaction.callback_url}
                              </p>
                            </div>
                          </div>
                          <div className="payment-link-details-copy-url-btn">
                            <CopyToClipboard
                              text={transactionDetails.data.transaction.callback_url}
                              onCopy={() => getSuccessNotificationMessage("Callback URL copied to clipboard!")}>
                              <Button className="copy-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#5C9EEB"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                                </svg>
                                <span>{t("copy")}</span>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      )}
                      {transactionDetails.data.transaction.receiver_wallet_address && (
                        <div className="payment-link-details-success-url-card bg-green">
                          <div className="payment-link-details-success-url-left-sec">
                            <div className="payment-link-details-success-url-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 13 14"
                              >
                                <path
                                  stroke="#269868"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2.076"
                                  d="M2.693 7.128l2.155 2.154 5.387-5.386"
                                ></path>
                              </svg>
                            </div>
                            <div className="payment-link-details-success-url-info">
                              <h5>{t("receiver_wallet_address")}</h5>
                              <p>{transactionDetails.data.transaction.receiver_wallet_address}</p>
                            </div>
                          </div>
                          <div className="payment-link-details-success-url-btn">
                            <CopyToClipboard
                              text={transactionDetails.data.transaction.receiver_wallet_address}
                              onCopy={() => getSuccessNotificationMessage("Success URL copied to clipboard!")}>
                              <Button className="success-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#5C9EEB"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                                </svg>
                                <span>{t("copy")}</span>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      )}
                      {transactionDetails.data.transaction.transaction_hash && (
                        <div className="payment-link-details-cancel-url-card bg-orange">
                          <div className="payment-link-details-cancel-url-left-sec">
                            <div className="payment-link-details-cancel-url-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  fill="#FF8A00"
                                  stroke="#FF8A00"
                                  strokeWidth="0.5"
                                  d="M11.001 4.176a5.111 5.111 0 011.117 3.199A5.119 5.119 0 017 12.493h0a5.098 5.098 0 01-3.2-1.123l7.201-7.194zM7 2.256h0a5.112 5.112 0 013.2 1.118l-7.195 7.194a5.067 5.067 0 01-1.123-3.193A5.118 5.118 0 017 2.257zM.75 7.376a6.251 6.251 0 0010.67 4.42A6.25 6.25 0 10.75 7.374z"
                                ></path>
                              </svg>
                            </div>
                            <div className="payment-link-details-cancel-url-info">
                              <h5>{t("cancel_url")}</h5>
                              <p>{transactionDetails.data.transaction.transaction_hash}</p>
                            </div>
                          </div>
                          <div className="payment-link-details-cancel-url-btn">
                            <CopyToClipboard
                              text={transactionDetails.data.transaction.transaction_hash}
                              onCopy={() => getSuccessNotificationMessage("Cancel URL copied to clipboard!")}>
                              <Button className="copy-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#5C9EEB"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                                </svg>
                                <span>{t("copy")}</span>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="efi-payment-url-sec">
                <Row className="g-3">
                  <Col md={6}>
                    <div className="payment-link-details-info-box mb-3">
                      <div className="payment-link-details-info-card">
                        <p>{t("status")}</p>
                        <h4 className="align-item-text-right" style={{ display: "flex", justifyContent: "flex-end" }}>
                          <div className={getStatusClass(transactionDetails.data.transaction.status)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill={getStatusColor(transactionDetails.data.transaction.status)}
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                            </svg>
                            {transactionDetails.data.transaction.status_formatted}
                          </div>
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("order_id")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.order_id}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("type")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payment_type}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("token")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.token} {transactionDetails.data.transaction.token_type}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("requested_amount")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.amount_formatted}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("created_date")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.created_at}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("updated_date")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.updated_at}
                        </h4>
                      </div>
                    </div>
                  </Col>
                  {transactionDetails.data.transaction.payload ? <Col md={6}>
                    <div className="payment-link-details-info-box">
                      <div className="payment-link-details-info-card">
                        <p>{t("name")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payload.name}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("email")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payload.email}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("phone")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payload.mobile}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("primary_billing_address")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payload.primary_address}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("secondary_billing_address")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payload.secondary_address}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("billing_city")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payload.city}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("billing_state")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payload.state}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("billing_country")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payload.country}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("billing_zipcode")}</p>
                        <h4 className="align-item-text-right">
                          {transactionDetails.data.transaction.payload.zipcode}
                        </h4>
                      </div>
                    </div>
                  </Col> : null}
                </Row>
              </div>
            </div>
          ) : null}
        </div>
      </div> */}
      {transactionDetails.loading ? (
        <div className="efi-widget-transaction-details">
          <div className="efi-widget-details-head">
            <div
              className="widget-details-full-wrapped"
              style={{ paddingBottom: "0" }}
            >
              {[...Array(1)].map((i, key) => (
                <Skeleton key={key} height={100} />
              ))}
            </div>
            <div className="widget-details-full-wrapped">
              <Row>
                <Col lg={6}>
                  <div className="efi-widget-details-main-flow">
                    <Skeleton height={100} />
                    <Skeleton height={150} />
                    <Skeleton height={250} />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="efi-widget-details-main-flow">
                    <Skeleton height={120} />
                    <Skeleton height={400} />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      ) : Object.keys(transactionDetails.data).length > 0 ? (
        <div className="efi-widget-transaction-details">
          <div className="efi-widget-details-head">
            <div className="efi-widget-details-head-flow">
              <div className="efi-widget-amount-info">
                <Link className="widget-back-btn" to={-1}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#000"
                      fillRule="evenodd"
                      d="M15 4a1 1 0 111.414 1.414l-5.879 5.879a1 1 0 000 1.414l5.88 5.879A1 1 0 0115 20l-7.293-7.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
                <div className="efi-amount-info-sec">
                  <h3>
                    {transactionDetails.data.transaction.amount_formatted}
                  </h3>
                  <p>Requested Amount</p>
                </div>
                <div className="efi-amount-info-sec">
                  <h3>
                    {transactionDetails.data.transaction.token}{" "}
                    {transactionDetails.data.transaction.token_type}
                  </h3>
                  <p>Token</p>
                </div>
              </div>
              <div className="efi-widget-amount-info-status">
                <div className="efi-amount-info-status">
                  {["USER_PAID", "TOKEN_RECEIVED"].includes(
                    transactionDetails.data.transaction.status
                  ) ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 24.02 24"
                    >
                      <g data-name="Layer 2">
                        <g data-name="Layer 1">
                          <path
                            fill={getStatusColor(transactionDetails.data.transaction.status)}
                            d="M22.31 9.76l-1-.76c.13-.46.24-.85.34-1.19.4-1.34.62-2.08.07-2.84s-1.3-.79-2.72-.83h-1.24c-.17-.44-.3-.83-.42-1.16-.46-1.32-.72-2-1.62-2.34s-1.53.14-2.69.94l-1 .69-1-.67C10 .79 9.24.29 8.33.59s-1.15 1-1.62 2.33c-.12.33-.26.72-.42 1.17L5 4.13c-1.4 0-2.17.05-2.72.81s-.28 1.5.1 2.85c.1.34.22.73.34 1.19l-1 .76C.63 10.58 0 11.05 0 12s.61 1.41 1.72 2.26l1 .76c-.12.46-.24.85-.34 1.19-.4 1.34-.62 2.08-.07 2.84s1.33.78 2.72.82h1.23c.17.44.3.83.42 1.16.47 1.32.72 2 1.62 2.34s1.53-.14 2.69-.94l1-.69 1 .67a4.2 4.2 0 002.19 1 1.71 1.71 0 00.53-.09c.9-.29 1.15-1 1.62-2.33.12-.33.25-.72.42-1.16h1.24c1.4 0 2.17-.05 2.72-.82s.34-1.5-.06-2.84c-.1-.34-.22-.73-.34-1.19l1-.76C23.41 13.43 24 13 24 12s-.59-1.39-1.69-2.24z"
                            data-original="#8dc63f"
                          ></path>
                          <path
                            fill="#fff"
                            d="M10.37 17.26a1 1 0 01-.71-.34l-2.95-3.29a1 1 0 011.5-1.33l2.22 2.51 6.33-6.16a1 1 0 111.39 1.43L11.07 17a1 1 0 01-.7.26z"
                            data-original="#ffffff"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  ) : ["PROCESSING", "CANCELLED", "INITIATED"].includes(
                      transactionDetails.data.transaction.status
                    ) ? (
                    <svg
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8516 3.07851C13.5564 1.76223 15.4436 1.76223 16.1484 3.07851V3.07851C16.6581 4.03037 17.8658 4.35397 18.7831 3.78447V3.78447C20.0517 2.99696 21.6861 3.94058 21.6383 5.43292V5.43292C21.6038 6.51211 22.4879 7.39618 23.5671 7.36166V7.36166C25.0594 7.31392 26.003 8.94832 25.2155 10.2169V10.2169C24.646 11.1342 24.9696 12.3419 25.9215 12.8516V12.8516C27.2378 13.5564 27.2378 15.4436 25.9215 16.1484V16.1484C24.9696 16.6581 24.646 17.8658 25.2155 18.7831V18.7831C26.003 20.0517 25.0594 21.6861 23.5671 21.6383V21.6383C22.4879 21.6038 21.6038 22.4879 21.6383 23.5671V23.5671C21.6861 25.0594 20.0517 26.003 18.7831 25.2155V25.2155C17.8658 24.646 16.6581 24.9696 16.1484 25.9215V25.9215C15.4436 27.2378 13.5564 27.2378 12.8516 25.9215V25.9215C12.3419 24.9696 11.1342 24.646 10.2169 25.2155V25.2155C8.94832 26.003 7.31392 25.0594 7.36166 23.5671V23.5671C7.39618 22.4879 6.51211 21.6038 5.43292 21.6383V21.6383C3.94058 21.6861 2.99696 20.0517 3.78447 18.7831V18.7831C4.35397 17.8658 4.03037 16.6581 3.07851 16.1484V16.1484C1.76223 15.4436 1.76223 13.5564 3.07851 12.8516V12.8516C4.03037 12.3419 4.35397 11.1342 3.78447 10.2169V10.2169C2.99696 8.94832 3.94058 7.31392 5.43292 7.36166V7.36166C6.51211 7.39618 7.39618 6.51211 7.36166 5.43292V5.43292C7.31392 3.94058 8.94832 2.99696 10.2169 3.78447V3.78447C11.1342 4.35397 12.3419 4.03037 12.8516 3.07851V3.07851Z"
                        fill={getStatusColor(transactionDetails.data.transaction.status)}
                      />
                      <path
                        d="M14.5 19C14.078 19 13.7227 19.1447 13.434 19.434C13.1447 19.7227 13 20.078 13 20.5C13 20.922 13.1447 21.2773 13.434 21.566C13.7227 21.8553 14.078 22 14.5 22C14.922 22 15.2773 21.8553 15.566 21.566C15.8553 21.2773 16 20.922 16 20.5C16 20.078 15.8553 19.7227 15.566 19.434C15.2773 19.1447 14.922 19 14.5 19ZM14.502 8.5C14.2253 8.5 13.989 8.59733 13.793 8.792C13.5977 8.986 13.5 9.222 13.5 9.5V15.23C13.5 15.508 13.597 15.7447 13.791 15.94C13.985 16.1333 14.2207 16.23 14.498 16.23C14.7747 16.23 15.011 16.1333 15.207 15.94C15.4023 15.7447 15.5 15.508 15.5 15.23V9.5C15.5 9.222 15.403 8.986 15.209 8.792C15.015 8.59733 14.7793 8.5 14.502 8.5Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8516 3.07851C13.5564 1.76223 15.4436 1.76223 16.1484 3.07851V3.07851C16.6581 4.03037 17.8658 4.35397 18.7831 3.78447V3.78447C20.0517 2.99696 21.6861 3.94058 21.6383 5.43292V5.43292C21.6038 6.51211 22.4879 7.39618 23.5671 7.36166V7.36166C25.0594 7.31392 26.003 8.94832 25.2155 10.2169V10.2169C24.646 11.1342 24.9696 12.3419 25.9215 12.8516V12.8516C27.2378 13.5564 27.2378 15.4436 25.9215 16.1484V16.1484C24.9696 16.6581 24.646 17.8658 25.2155 18.7831V18.7831C26.003 20.0517 25.0594 21.6861 23.5671 21.6383V21.6383C22.4879 21.6038 21.6038 22.4879 21.6383 23.5671V23.5671C21.6861 25.0594 20.0517 26.003 18.7831 25.2155V25.2155C17.8658 24.646 16.6581 24.9696 16.1484 25.9215V25.9215C15.4436 27.2378 13.5564 27.2378 12.8516 25.9215V25.9215C12.3419 24.9696 11.1342 24.646 10.2169 25.2155V25.2155C8.94832 26.003 7.31392 25.0594 7.36166 23.5671V23.5671C7.39618 22.4879 6.51211 21.6038 5.43292 21.6383V21.6383C3.94058 21.6861 2.99696 20.0517 3.78447 18.7831V18.7831C4.35397 17.8658 4.03037 16.6581 3.07851 16.1484V16.1484C1.76223 15.4436 1.76223 13.5564 3.07851 12.8516V12.8516C4.03037 12.3419 4.35397 11.1342 3.78447 10.2169V10.2169C2.99696 8.94832 3.94058 7.31392 5.43292 7.36166V7.36166C6.51211 7.39618 7.39618 6.51211 7.36166 5.43292V5.43292C7.31392 3.94058 8.94832 2.99696 10.2169 3.78447V3.78447C11.1342 4.35397 12.3419 4.03037 12.8516 3.07851V3.07851Z"
                        fill={getStatusColor(transactionDetails.data.transaction.status)}
                      />
                      <path
                        d="M17.5 11L11 17.5M11 11L17.5 17.5"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                  <h3>{transactionDetails.data.transaction.status}</h3>
                </div>
                <div className="efi-amount-info-status d-inline">
                  <p>
                    {" "}
                    <span>Updated</span>{" "}
                    {formatDate(
                      transactionDetails.data.transaction.created_utc
                    )}{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="widget-details-full-wrapped">
              <Row>
                <Col lg={transactionDetails.data.transaction.payload ? 6 : 12}>
                  <div className="efi-widget-details-main-flow">
                    <div className="efi-card-details-main">
                      <div className="efi-card-details-main-title">
                        <h3>Wallet Address</h3>
                      </div>
                      <div className="efi-card-details-individuals">
                        <h4>
                          {transactionDetails.data.transaction.receiver_wallet_address.slice(
                            0,
                            20
                          )}
                          ...
                          {transactionDetails.data.transaction.receiver_wallet_address.slice(
                            -20
                          )}
                        </h4>
                        <CopyToClipboard
                          text={
                            transactionDetails.data.transaction
                              .receiver_wallet_address
                          }
                          onCopy={() =>
                            getSuccessNotificationMessage(t("address_copied"))
                          }
                        >
                          <Button className="copy-widget">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              enableBackground="new 0 0 512 512"
                              viewBox="0 0 6.35 6.35"
                            >
                              <path
                                fill="#878e96"
                                fillRule="evenodd"
                                d="M1.06.265a.8.8 0 00-.795.795v3.71a.265.265 0 10.53 0V1.06A.26.26 0 011.06.795h3.71a.265.265 0 100-.53zm1.06 1.06a.8.8 0 00-.796.796V5.3a.8.8 0 00.797.796h3.178a.8.8 0 00.797-.796V2.121a.8.8 0 00-.797-.796z"
                                data-original="#000000"
                                paintOrder="stroke fill markers"
                              ></path>
                            </svg>
                            <span>{t("copy")}</span>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="efi-card-details-main">
                      <div className="efi-card-details-main-title">
                        <h3>Order Details</h3>
                      </div>
                      <div className="efi-grid-element-two">
                        <div className="efi-card-details-trans-form">
                          <label>Order ID</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {transactionDetails.data.transaction.order_id}
                            </h4>
                          </div>
                        </div>
                        <div className="efi-card-details-trans-form">
                          <label>Type</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {transactionDetails.data.transaction.payment_type}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="efi-card-details-trans-form">
                        <label>Callback URL</label>
                        <div className="efi-card-details-individuals">
                          <h4>
                            {transactionDetails.data.transaction.callback_url}
                          </h4>
                          <CopyToClipboard
                            text={
                              transactionDetails.data.transaction.callback_url
                            }
                            onCopy={() =>
                              getSuccessNotificationMessage(t("url_copied"))
                            }
                          >
                            <Button className="copy-widget">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 6.35 6.35"
                              >
                                <path
                                  fill="#878e96"
                                  fillRule="evenodd"
                                  d="M1.06.265a.8.8 0 00-.795.795v3.71a.265.265 0 10.53 0V1.06A.26.26 0 011.06.795h3.71a.265.265 0 100-.53zm1.06 1.06a.8.8 0 00-.796.796V5.3a.8.8 0 00.797.796h3.178a.8.8 0 00.797-.796V2.121a.8.8 0 00-.797-.796z"
                                  data-original="#000000"
                                  paintOrder="stroke fill markers"
                                ></path>
                              </svg>
                              <span>{t("copy")}</span>
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                    <div className="efi-card-details-main">
                      <div className="efi-card-details-main-title">
                        <h3>ID’s</h3>
                      </div>
                      {transactionDetails.data.transaction.transaction_hash ? (
                        <div className="efi-card-details-trans-form mb-3">
                          <label>Transaction Hash</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {
                                transactionDetails.data.transaction
                                  .transaction_hash
                              }
                            </h4>
                            <CopyToClipboard
                              text={
                                transactionDetails.data.transaction
                                  .transaction_hash
                              }
                              onCopy={() =>
                                getSuccessNotificationMessage(t("hash_copied"))
                              }
                            >
                              <Button className="copy-widget">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 6.35 6.35"
                                >
                                  <path
                                    fill="#878e96"
                                    fillRule="evenodd"
                                    d="M1.06.265a.8.8 0 00-.795.795v3.71a.265.265 0 10.53 0V1.06A.26.26 0 011.06.795h3.71a.265.265 0 100-.53zm1.06 1.06a.8.8 0 00-.796.796V5.3a.8.8 0 00.797.796h3.178a.8.8 0 00.797-.796V2.121a.8.8 0 00-.797-.796z"
                                    data-original="#000000"
                                    paintOrder="stroke fill markers"
                                  ></path>
                                </svg>
                                <span>{t("copy")}</span>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      ) : null}
                      {transactionDetails.data.transaction.payment_id ? (
                        <div className="efi-card-details-trans-form mb-3">
                          <label>Payment ID</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {transactionDetails.data.transaction.payment_id}
                            </h4>
                            <CopyToClipboard
                              text={
                                transactionDetails.data.transaction.payment_id
                              }
                              onCopy={() =>
                                getSuccessNotificationMessage(t("id_copied"))
                              }
                            >
                              <Button className="copy-widget">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 6.35 6.35"
                                >
                                  <path
                                    fill="#878e96"
                                    fillRule="evenodd"
                                    d="M1.06.265a.8.8 0 00-.795.795v3.71a.265.265 0 10.53 0V1.06A.26.26 0 011.06.795h3.71a.265.265 0 100-.53zm1.06 1.06a.8.8 0 00-.796.796V5.3a.8.8 0 00.797.796h3.178a.8.8 0 00.797-.796V2.121a.8.8 0 00-.797-.796z"
                                    data-original="#000000"
                                    paintOrder="stroke fill markers"
                                  ></path>
                                </svg>
                                <span>{t("copy")}</span>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      ) : null}
                      {transactionDetails.data.transaction
                        .transaction_unique_id ? (
                        <div className="efi-card-details-trans-form mb-3">
                          <label>Transaction ID</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {
                                transactionDetails.data.transaction
                                  .transaction_unique_id
                              }
                            </h4>
                            <CopyToClipboard
                              text={
                                transactionDetails.data.transaction
                                  .transaction_unique_id
                              }
                              onCopy={() =>
                                getSuccessNotificationMessage(
                                  t("transaction_copied")
                                )
                              }
                            >
                              <Button className="copy-widget">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 6.35 6.35"
                                >
                                  <path
                                    fill="#878e96"
                                    fillRule="evenodd"
                                    d="M1.06.265a.8.8 0 00-.795.795v3.71a.265.265 0 10.53 0V1.06A.26.26 0 011.06.795h3.71a.265.265 0 100-.53zm1.06 1.06a.8.8 0 00-.796.796V5.3a.8.8 0 00.797.796h3.178a.8.8 0 00.797-.796V2.121a.8.8 0 00-.797-.796z"
                                    data-original="#000000"
                                    paintOrder="stroke fill markers"
                                  ></path>
                                </svg>
                                <span>{t("copy")}</span>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                {transactionDetails.data.transaction.payload ? (
                  <Col lg={6}>
                    <div className="efi-widget-details-main-flow">
                      <div className="efi-widget-profile-info">
                        <div className="efi-widget-profie-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 460.8 460.8"
                          >
                            <path
                              fill="#5a36a8"
                              d="M230.432 0c-65.829 0-119.641 53.812-119.641 119.641s53.812 119.641 119.641 119.641 119.641-53.812 119.641-119.641S296.261 0 230.432 0zm205.323 334.89c-3.135-7.837-7.314-15.151-12.016-21.943-24.033-35.527-61.126-59.037-102.922-64.784-5.224-.522-10.971.522-15.151 3.657-21.943 16.196-48.065 24.555-75.233 24.555s-53.29-8.359-75.233-24.555c-4.18-3.135-9.927-4.702-15.151-3.657-41.796 5.747-79.412 29.257-102.922 64.784-4.702 6.792-8.882 14.629-12.016 21.943-1.567 3.135-1.045 6.792.522 9.927 4.18 7.314 9.404 14.629 14.106 20.898 7.314 9.927 15.151 18.808 24.033 27.167 7.314 7.314 15.673 14.106 24.033 20.898 41.273 30.825 90.906 47.02 142.106 47.02s100.833-16.196 142.106-47.02c8.359-6.269 16.718-13.584 24.033-20.898 8.359-8.359 16.718-17.241 24.033-27.167 5.224-6.792 9.927-13.584 14.106-20.898 2.611-3.135 3.133-6.793 1.566-9.927z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </div>
                        <div className="efi-widget-profie-info">
                          <h4>
                            {transactionDetails.data.transaction.payload.name}
                          </h4>
                          <ul className="list-unstyled">
                            <li>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 512 512"
                              >
                                <g fill="#878e96">
                                  <path
                                    d="M298.789 313.693c-12.738 8.492-27.534 12.981-42.789 12.981-15.254 0-30.05-4.489-42.788-12.981L3.409 173.82A76.269 76.269 0 010 171.403V400.6c0 26.278 21.325 47.133 47.133 47.133h417.733c26.278 0 47.133-21.325 47.133-47.133V171.402a75.21 75.21 0 01-3.416 2.422z"
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M20.05 148.858l209.803 139.874c7.942 5.295 17.044 7.942 26.146 7.942 9.103 0 18.206-2.648 26.148-7.942L491.95 148.858c12.555-8.365 20.05-22.365 20.05-37.475 0-25.981-21.137-47.117-47.117-47.117H47.117C21.137 64.267 0 85.403 0 111.408a44.912 44.912 0 0020.05 37.45z"
                                    data-original="#000000"
                                  ></path>
                                </g>
                              </svg>
                              <span>
                                {
                                  transactionDetails.data.transaction.payload
                                    .email
                                }
                              </span>
                            </li>
                            <li>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 25.625 25.625"
                              >
                                <path
                                  fill="#878e96"
                                  d="M22.079 17.835c-1.548-1.324-3.119-2.126-4.648-.804l-.913.799c-.668.58-1.91 3.29-6.712-2.234-4.801-5.517-1.944-6.376-1.275-6.951l.918-.8c1.521-1.325.947-2.993-.15-4.71l-.662-1.04C7.535.382 6.335-.743 4.81.58l-.824.72c-.674.491-2.558 2.087-3.015 5.119-.55 3.638 1.185 7.804 5.16 12.375 3.97 4.573 7.857 6.87 11.539 6.83 3.06-.033 4.908-1.675 5.486-2.272l.827-.721c1.521-1.322.576-2.668-.973-3.995l-.931-.801z"
                                  data-original="#030104"
                                ></path>
                              </svg>
                              <span>
                                {
                                  transactionDetails.data.transaction.payload
                                    .mobile
                                }
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="efi-card-details-main">
                        <div className="efi-card-details-main-title">
                          <h3>Billing Address</h3>
                        </div>
                        <div className="efi-card-details-trans-form mb-3">
                          <label>Primary Billing Address</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {
                                transactionDetails.data.transaction.payload
                                  .primary_address
                              }
                            </h4>
                          </div>
                        </div>
                        <div className="efi-card-details-trans-form mb-3">
                          <label>Secondary Billing Address</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {
                                transactionDetails.data.transaction.payload
                                  .secondary_address
                              }
                            </h4>
                          </div>
                        </div>
                        <div className="efi-card-details-trans-form mb-3">
                          <label>City</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {transactionDetails.data.transaction.payload.city}
                            </h4>
                          </div>
                        </div>
                        <div className="efi-card-details-trans-form mb-3">
                          <label>State</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {
                                transactionDetails.data.transaction.payload
                                  .state
                              }
                            </h4>
                          </div>
                        </div>
                        <div className="efi-card-details-trans-form mb-3">
                          <label>Country</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {
                                transactionDetails.data.transaction.payload
                                  .country
                              }
                            </h4>
                          </div>
                        </div>
                        <div className="efi-card-details-trans-form mb-3">
                          <label>Zipcode</label>
                          <div className="efi-card-details-individuals">
                            <h4>
                              {
                                transactionDetails.data.transaction.payload
                                  .zipcode
                              }
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default TransactionDetails;
