import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { transactionCompleteStart } from "../../../../store/slices/PaymentSlice";

const PaymentStepTwo = (props) => {
  
  const dispatch = useDispatch();
  const t = useTranslation();
  const formRef = useRef(null);
  const transactionComplete = useSelector(state => state.payment.transactionComplete);

  const cardSchema = Yup.object().shape({
    card_number: Yup.string()
      .required("Card number is required")
      .test(
        "len",
        "Card number must be a 16-digit number",
        (val) => String(val).length === 16
      ),
    cvv: Yup.string()
      .required("CVV is required")
      .matches(/^\d{3,5}$/, "CVV must be a 3-digit number"),
    expiry_month: Yup.string()
      .required("Expiry month is required")
      .matches(
        /^(0[1-9]|1[0-2])$/,
        "Expiry month must be a two-digit number between 01 and 12"
      ),
    expiry_year: Yup.number()
      .required("Expiry year is required")
      .min(
        new Date().getFullYear(),
        `Expiry year must be greater than or equal to ${new Date().getFullYear()}`
      )
      .typeError("Expiry year must be a four-digit number")
      .integer("Expiry year must be a four-digit number")
      .test(
        "len",
        "Expiry year must be a four-digit number",
        (val) => String(val).length === 4
      ),
  });

  const onCardTransaction = (values) => {
    dispatch(transactionCompleteStart({
      payment_type: props.paymentType,
      order_id: props.data.order_id,
      ...values
    }));
  };

  return (
    <>
        <div className="efi-widget-method-main-title">
          <h3>Card Details</h3>
          <Button onClick={() => props.setStep(1)}>
            Other Payment Method
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              x="0"
              y="0"
              enableBackground="new 0 0 240.823 240.823"
              version="1.1"
              viewBox="0 0 240.823 240.823"
              xmlSpace="preserve"
              fill="#5A36A8"
            >
              <path d="M183.189 111.816L74.892 3.555c-4.752-4.74-12.451-4.74-17.215 0-4.752 4.74-4.752 12.439 0 17.179l99.707 99.671-99.695 99.671c-4.752 4.74-4.752 12.439 0 17.191 4.752 4.74 12.463 4.74 17.215 0l108.297-108.261c4.68-4.691 4.68-12.511-.012-17.19z"></path>
            </svg>
          </Button>
        </div>
        <Formik
          initialValues={{
            card_number: "",
            cvv: "",
            expiry_month: "",
            expiry_year: "",
          }}
          validationSchema={cardSchema}
          innerRef={formRef}
          onSubmit={(values) => onCardTransaction(values)}
        >
          {({ values, touched, errors, setFieldValue }) => (
            <FORM className="widget-form">
              <Row>
                <Form.Group className="mb-3" controlId="formGridAddress2">
                  <Form.Label className="w-100">
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={"Enter Card Number"}
                      name="card_number"
                    />
                    <span>Card Number</span>
                  </Form.Label>
                  <ErrorMessage
                    component={"div"}
                    name="card_number"
                    className="text-danger text-right"
                  />
                </Form.Group>

                <Form.Group className="mb-3" as={Col} controlId="formGridEmail">
                  <Form.Label className="w-100">
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter expiry month"
                      name="expiry_month"
                    />

                    <span> Expiry Date</span>
                  </Form.Label>
                  <ErrorMessage
                    component={"div"}
                    name="expiry_month"
                    className="text-danger text-right"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  as={Col}
                  controlId="formGridPassword"
                >
                  <Form.Label className="w-100">
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter expiry year"
                      name="expiry_year"
                    />

                    <span>Expiry Year</span>
                  </Form.Label>
                  <ErrorMessage
                    component={"div"}
                    name="expiry_year"
                    className="text-danger text-right"
                  />
                </Form.Group>

                <Form.Group controlId="formGridPassword">
                  <Form.Label className="w-100">
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter CVV"
                      name="cvv"
                    />
                    <span> CVV</span>
                  </Form.Label>
                  <ErrorMessage
                    component={"div"}
                    name="cvv"
                    className="text-danger text-right"
                  />
                </Form.Group>
              </Row>
              <div className="efi-widget-action-frame mb-3">
                <Button className="theme-primary-btn" type="submit" disabled={transactionComplete.buttonDisable}>
                  {transactionComplete.buttonDisable ?"Loading" : "Confirm"}
                </Button>
              </div>
            </FORM>
          )}
        </Formik>
    </>
  );
};

export default PaymentStepTwo;
