import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { transactionListStart } from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound"
import { useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";

const RecentTransaction = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("dashboard.recent_transaction");
  const profile = useSelector((state) => state.admin.profile);
  const transactionList = useSelector((state) => state.payment.transactionList);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        transactionListStart({
          skip: 0,
          take: 6,
        })
      );
    }
  }, [profile]);

  const getStatusClass = (status) => {
    switch (status) {
      case "INITIATED":
        return "payment-initiated";
      case "PROCESSING":
        return "payment-onhold";
      case "USER_PAID":
        return "payment-paid";
      case "REJECTED":
        return "payment-rejected";
      case "CANCELLED":
        return "payment-cancelled";
      case "FAILED": 
        return "payment-failed";
      case "TOKEN_RECEIVED": 
        return "payment-transfered";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "INITIATED":
        return "#5C9EEB";
      case "PROCESSING":
        return "#FFCE22";
      case "USER_PAID":
        return "#197E23";
      case "REJECTED":
        return "#818181";
      case "CANCELLED":
        return "#FF8A00";
      case "FAILED":
        return "#FF6A6A";
      case "TOKEN_RECEIVED":
        return "#5A36A8";
      default:
        return "#5C9EEB";
    }
  };

  function formatDate(dateString) {
    let timeZone = profile.data.timezone || "Asia/Kolkata";
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZone };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }

  return (
    <>
      <div className="recent-transaction-sec">
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%"}}>
          <h3>{t("title")}</h3>
          <Link to="/transactions">
            {t("view_all")}
          </Link>
        </div>
        <div className="recent-transaction-table-sec  table-responsive">
          {transactionList.loading ? (
            [...Array(3)].map((i, key) => <Skeleton key={key} height={50} />)
          ) : Object.keys(transactionList.data).length > 0 ? (
            <React.Fragment>
              <Table>
                <thead>
                  <th>{t("sl_no")}</th>
                  <th>{t("order_id")}</th>
                  <th>{t("type")}</th>
                  <th>{t("view.token")}</th>
                  <th>{t("view.amount")}</th>
                  <th>{t("created_date")}</th>
                  <th>{t("status")}</th>
                </thead>
                <tbody>
                  {transactionList?.data?.transactions?.length > 0 ? (
                    transactionList.data.transactions.slice(0,6).map((transaction, i) => (
                      <tr key={i}>
                        <td>{(i+1)}</td>
                        <td>{transaction.order_id}</td>
                        <td>{transaction.payment_type}</td>
                        <td>{transaction.token} {transaction.token_type}</td>
                        <td>{transaction.currency_symbol}{" "}{transaction.amount.toFixed(2)}</td>
                        <td>{formatDate(transaction.created_utc)}</td>
                        <td>
                          <div className={getStatusClass(transaction.status)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill={getStatusColor(transaction.status)}
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                            </svg>
                            {transaction.status}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <NoDataFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          <div></div>
        </div>
      </div>
    </>
  );
};

export default RecentTransaction;
