import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  paymentBulkActionFailure,
  paymentBulkActionSuccess,
  paymentDeleteFailure,
  paymentDeleteSuccess,
  paymentListFailure,
  paymentListSuccess,
  paymentSaveFailure,
  paymentSaveSuccess,
  paymentStatusFailure,
  paymentStatusSuccess,
  paymentViewFailure,
  paymentViewSuccess,
  billingAccountsFailure,
  billingAccountsSuccess,
  payInGenerateSuccess,
  payInGenerateFailure,
  payOutGenerateSuccess,
  payOutGenerateFailure,
  transactionListSuccess,
  transactionViewSuccess,
  transactionViewFailure,
  transactionUpdateFailure,
  transactionUpdateSuccess,
  transactionCancelFailure,
  transactionCancelSuccess,
  initiateStylopayFailure,
  initiateStylopaySuccess,
  upiSaveSuccess,
  upiSaveFailure,
  upiListSuccess,
  upiListFailure,
  upiDeleteSuccess,
  upiDeleteFailure,
  upiListStart,
  transactionStatusSuccess,
  transactionStatusFailure,
  invoicePaymentListSuccess,
  invoicePaymentListFailure,
  invoicePaymentStatusSuccess,
  invoicePaymentViewSuccess,
  invoicePaymentStatusFailure,
  invoicePaymentDeleteSuccess,
  invoicePaymentDeleteFailure,
  invoicePaymentViewFailure,
  invoicePaymentSaveSuccess,
  invoicePaymentSaveFailure,
  customizePaymentViewSuccess,
  customizePaymentSaveSuccess,
  customizePaymentSaveFailure,
  customizePaymentResetSuccess,
  customizePaymentResetFailure,
  exportPayinTransactionsSuccess,
  exportPayinTransactionsFailure,
  transactionCompleteSuccess,
  transactionCompleteFailure,
  transactionDetailsSuccess,
  transactionDetailsFailure,
} from "../slices/PaymentSlice";


function* paymentListAPI(action) {
  yield getCommonSaga({
    apiUrl: "payment_links",
    payload: action.payload,
    successNotify: false,
    success: paymentListSuccess,
    failure: paymentListFailure,
  });
}

function* paymentStatusAPI(action) {
  const user_paymentsListData = yield select(state => state.payment.paymentList.data);
  const paymentViewData = yield select(state => state.payment.paymentView.data);
  yield getCommonSaga({
    apiUrl: "user_payments/status",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(paymentStatusSuccess(data));
      if (Object.keys(user_paymentsListData).length > 0)
        store.dispatch(paymentListSuccess({
          ...user_paymentsListData,
          user_payments: user_paymentsListData.user_payments.map((payment) => payment.payment_id === action.payload.payment_id ? data.payment : payment),
        }));
      if (Object.keys(paymentViewData).length > 0 && paymentViewData.payment.payment_id === action.payload.payment_id)
        store.dispatch(paymentViewSuccess(data));
    },
    failure: paymentStatusFailure,
  })
}

function* paymentBulkActionAPI(action) {
  const user_paymentsListData = yield select(state => state.payment.paymentList.data);
  const { paymentIds, ...payload } = action.payload;
  yield getCommonSaga({
    apiUrl: "user_payments/bulk_action",
    payload: payload,
    successCallback: true,
    success: data => {
      store.dispatch(paymentBulkActionSuccess({ data: data }));
      if (payload.action_type === 3)
        store.dispatch(paymentListSuccess(data))
      else
        store.dispatch(paymentListSuccess({
          ...user_paymentsListData,
          user_payments: user_paymentsListData.user_payments.map(payment => paymentIds.includes(payment.payment_id) ?
            { ...payment, status: payload.action_type === 1 ? 1 : 0 }
            : payment
          )
        }))
    },
    failure: paymentBulkActionFailure,
  })
}

function* paymentDeleteAPI(action) {
  const user_paymentsListData = yield select(state => state.payment.paymentList.data);
  yield getCommonSaga({
    apiUrl: "payment_links/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(paymentDeleteSuccess({ data: data }));
      if (Object.keys(user_paymentsListData).length > 0)
        store.dispatch(paymentListSuccess({
          payment_links: [
            ...user_paymentsListData.payment_links.filter(cat => cat.id !== action.payload.payment_link_id)
          ],
          total_payment_links: data.total_payment_links,
        }))
    },
    failure: paymentDeleteFailure
  })
}

function* paymentViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "payment_links/show",
    payload: action.payload,
    successNotify: false,
    success: paymentViewSuccess,
    failure: paymentViewFailure,
  });
}

function* paymentSaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "payment_links/store",
    payload: action.payload,
    successNotify: true,
    success: paymentSaveSuccess,
    failure: paymentSaveFailure,
  });
}

function* billingAccountsAPI(action) {
  yield getCommonSaga({
    apiUrl: "billing_accounts",
    payload: action.payload,
    successNotify: false,
    success: billingAccountsSuccess,
    failure: billingAccountsFailure,
  });
}

function* payInGenerateAPI(action) {
  yield getCommonSaga({
    apiUrl: "payment_links/store",
    payload: action.payload,
    successNotify: true,
    success: payInGenerateSuccess,
    failure: payInGenerateFailure,
  });
}

function* payOutGenerateAPI(action) {
  yield getCommonSaga({
    apiUrl: "payment_links/store",
    payload: action.payload,
    successNotify: true,
    success: payOutGenerateSuccess,
    failure: payOutGenerateFailure,
  });
}

function* transactionListAPI(action) {
  yield getCommonSaga({
    apiUrl: "transaction_list",
    payload: action.payload,
    successNotify: false,
    success: transactionListSuccess,
    failure: transactionListSuccess,
  });
}

function* transactionViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "transaction_view",
    payload: action.payload,
    successNotify: false,
    errorNotify: false,
    success: transactionViewSuccess,
    failure: transactionViewFailure,
  });
}

function* transactionCompleteAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_transaction",
    payload: action.payload,
    successNotify: false,
    errorNotify: true,
    success: transactionCompleteSuccess,
    failure: transactionCompleteFailure,
  });
}

function* transactionUpdateAPI(action) {
  const transactionView = yield select(state => state.payment.transactionView.data);
  yield getCommonSaga({
    apiUrl: "transactions/update",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(transactionUpdateSuccess(data));
      store.dispatch(transactionViewSuccess({
        ...transactionView,
        transaction: data.transaction
      }));
    },
    failure: transactionUpdateFailure,
  });
}

function* transactionCancelAPI(action) {
  const transactionView = yield select(state => state.payment.transactionView.data);
  yield getCommonSaga({
    apiUrl: "transactions/cancel",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(transactionCancelSuccess(data));
      store.dispatch(transactionViewSuccess({
        ...transactionView,
        transaction: data.transaction
      }));
    },
    failure: transactionCancelFailure,
  });
}

function* transactionStatusAPI(action) {
  const transactionListData = yield select(state => state.payment.transactionList.data);
  yield getCommonSaga({
    apiUrl: "transactions/status",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(transactionStatusSuccess(data));
      if (Object.keys(transactionListData).length > 0)
        store.dispatch(transactionListSuccess({
          ...transactionListData,
          transactions: transactionListData.transactions.map((transaction)=> transaction.id === data.transaction.id ? data.transaction : transaction),
        }))
    },
    failure: transactionStatusFailure,
  })
}
    
function* initiateStylopayAPI(action) {
  yield getCommonSaga({
    apiUrl: "transactions/initiate_stylopay",
    payload: action.payload,
    successNotify: false,
    success: initiateStylopaySuccess,
    failure: initiateStylopayFailure,
  });
}

function* upiSaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_upis/store",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(upiSaveSuccess(data));
      store.dispatch(upiListStart());
    },
    failure: upiSaveFailure,
  });
}

function* upiListAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_upis",
    payload: action.payload,
    successNotify: false,
    success: upiListSuccess,
    failure: upiListFailure,
  });
}

function* upiDeleteAPI(action) {
  const upiListData = yield select(state => state.payment.upiList.data);
  yield getCommonSaga({
    apiUrl: "user_upis/destroy",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(upiDeleteSuccess({ data: data }));
      if (Object.keys(upiListData).length > 0)
        store.dispatch(upiListSuccess({
          user_upis: [
            ...upiListData.user_upis.filter(cat => cat.id !== action.payload.user_upi_id)
          ],
          total_user_upis: data.total_user_upis,
        }))
    },
    failure: upiDeleteFailure,
  });
}

function* invoicePaymentListAPI(action) {
  yield getCommonSaga({
    apiUrl: "invoice_links",
    payload: action.payload,
    successNotify: false,
    success: invoicePaymentListSuccess,
    failure: invoicePaymentListFailure,
  });
}


function* invoicePaymentStatusAPI(action) {
  const invoicePaymentListData = yield select(state => state.payment.invoicePaymentList.data);
  const invoicePaymentViewData = yield select(state => state.payment.invoicePaymentView.data);
  yield getCommonSaga({
    apiUrl: "invoice_links/status",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(invoicePaymentStatusSuccess(data));
      if (Object.keys(invoicePaymentListData).length > 0)
        store.dispatch(invoicePaymentListSuccess({
          ...invoicePaymentListData,
          invoice_payments: invoicePaymentListData.invoice_payments.map((payment) => payment.payment_id === action.payload.payment_id ? data.payment : payment),
        }));
      if (Object.keys(invoicePaymentViewData).length > 0 && invoicePaymentViewData.payment.payment_id === action.payload.payment_id)
        store.dispatch(invoicePaymentViewSuccess(data));
    },
    failure: invoicePaymentStatusFailure,
  })
}

function* invoicePaymentDeleteAPI(action) {
  const invoicePaymentListData = yield select(state => state.payment.invoicePaymentList.data);
  yield getCommonSaga({
    apiUrl: "invoice_links/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(invoicePaymentDeleteSuccess({ data: data }));
      if (Object.keys(invoicePaymentListData).length > 0)
        store.dispatch(invoicePaymentListSuccess({
          invoice_links: [
            ...invoicePaymentListData.invoice_links.filter(cat => cat.id !== action.payload.invoice_link_id)
          ],
          total_invoice_links: data.total_invoice_links,
        }))
    },
    failure: invoicePaymentDeleteFailure
  })
}

function* invoicePaymentViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "invoice_links/show",
    payload: action.payload,
    successNotify: false,
    success: invoicePaymentViewSuccess,
    failure: invoicePaymentViewFailure,
  });
}

function* invoicePaymentSaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "invoice_links/store",
    payload: action.payload,
    successNotify: true,
    success: invoicePaymentSaveSuccess,
    failure: invoicePaymentSaveFailure,
  });
}

function* customizePaymentViewStartAPI(action) {
  yield getCommonSaga({
    apiUrl: "payment_pages/show",
    payload: action.payload,
    successNotify: false,
    success: customizePaymentViewSuccess,
    failure: customizePaymentViewSuccess,
  });
}

function* customizePaymentSaveStartAPI(action) {
  const customizePaymentViewData = yield select(state => state.payment.customizePaymentView.data);
  yield getCommonSaga({
    apiUrl: "payment_pages/store",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(customizePaymentSaveSuccess(data));
      if (Object.keys(customizePaymentViewData).length > 0)
        store.dispatch(customizePaymentViewSuccess({
          ...customizePaymentViewData,
          payment_page: data.payment_page
        }));
    },
    failure: customizePaymentSaveFailure,
  });
}

function* customizePaymentResetStartAPI(action) {
  const customizePaymentViewData = yield select(state => state.payment.customizePaymentView.data);
  yield getCommonSaga({
    apiUrl: "payment_pages/reset",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(customizePaymentResetSuccess(data));
      if (Object.keys(customizePaymentViewData).length > 0)
        store.dispatch(customizePaymentViewSuccess({
          ...customizePaymentViewData,
          payment_page: data.payment_page
        }));
    },
    failure: customizePaymentResetFailure,
  });
}

function* exportPayinTransactionsAPI(action) {
  yield getCommonSaga({
    apiUrl: "export_transactions",
    payload: action.payload,
    successNotify: false,
    success: exportPayinTransactionsSuccess,
    failure: exportPayinTransactionsFailure,
  });
}

function* transactionDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "transaction_details",
    payload: action.payload,
    successNotify: false,
    success: transactionDetailsSuccess,
    failure: transactionDetailsFailure,
  });
}


export default function* PaymentSaga() {
  yield all([
    yield takeLatest('payment/paymentListStart', paymentListAPI),
    yield takeLatest('payment/paymentStatusStart', paymentStatusAPI),
    yield takeLatest('payment/paymentBulkActionStart', paymentBulkActionAPI),
    yield takeLatest('payment/paymentDeleteStart', paymentDeleteAPI),
    yield takeLatest('payment/paymentViewStart', paymentViewAPI),
    yield takeLatest('payment/paymentSaveStart', paymentSaveAPI),
    yield takeLatest('payment/billingAccountsStart', billingAccountsAPI),
    yield takeLatest('payment/payInGenerateStart', payInGenerateAPI),
    yield takeLatest('payment/payOutGenerateStart', payOutGenerateAPI),
    yield takeLatest('payment/transactionListStart', transactionListAPI),
    yield takeLatest('payment/transactionViewStart', transactionViewAPI),
    yield takeLatest('payment/transactionCompleteStart', transactionCompleteAPI),
    yield takeLatest('payment/transactionUpdateStart', transactionUpdateAPI),
    yield takeLatest('payment/transactionCancelStart', transactionCancelAPI),
    yield takeLatest('payment/transactionStatusStart', transactionStatusAPI),
    yield takeLatest('payment/initiateStylopayStart', initiateStylopayAPI),
    yield takeLatest('payment/upiSaveStart', upiSaveAPI),
    yield takeLatest('payment/upiListStart', upiListAPI),
    yield takeLatest('payment/upiDeleteStart', upiDeleteAPI),
    yield takeLatest('payment/invoicePaymentListStart', invoicePaymentListAPI),
    yield takeLatest('payment/invoicePaymentStatusStart', invoicePaymentStatusAPI),
    yield takeLatest('payment/invoicePaymentDeleteStart', invoicePaymentDeleteAPI),
    yield takeLatest('payment/invoicePaymentViewStart', invoicePaymentViewAPI),
    yield takeLatest('payment/invoicePaymentSaveStart', invoicePaymentSaveAPI),
    yield takeLatest('payment/customizePaymentViewStart', customizePaymentViewStartAPI),
    yield takeLatest('payment/customizePaymentSaveStart', customizePaymentSaveStartAPI),
    yield takeLatest('payment/customizePaymentResetStart', customizePaymentResetStartAPI),
    yield takeLatest('payment/exportPayinTransactionsStart', exportPayinTransactionsAPI),
    yield takeLatest('payment/transactionDetailsStart', transactionDetailsAPI),
  ]);
}

