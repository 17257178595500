import { all, fork } from "redux-saga/effects";
import AdminSaga from "./AdminSaga";
import CategorySaga from "./CategorySaga";
import PaymentSaga from "./PaymentSaga";
import WithdrawSaga from "./WithdrawSaga";
import PayoutSaga from "./PayoutSaga";

export default function* rootSaga() {
    yield all([
        fork(AdminSaga),
        fork(CategorySaga),
        fork(PaymentSaga),
        fork(WithdrawSaga),
        fork(PayoutSaga),
    ]);
}
