import React from "react";
import { Image, Form, Button } from "react-bootstrap";

const PaymentStepOne = ({ paymentType, setPaymentType, setStep, data }) => {
  return (
    <>
        <div className="efi-widget-method-main-title">
          <h3>Choose Payment Method</h3>
        </div>
        <Form className="widget-form">
          <div className="efi-check-card-frame">
            <label for="radio-card-1" className="efi-radio-card">
              <input
                type="radio"
                name="radio-card"
                id="radio-card-1"
                checked={paymentType == "UPI"}
                value="UPI"
                onClick={() => setPaymentType("UPI")}
              />
              <div className="efi-custome-check-card">
                <div className="efi-check-pay">
                  <Image
                    className="payment-type-icon"
                    src={window.location.origin + "/img/widgets/upi-avater.png"}
                    type="image/png"
                  />
                  <h3>UPI</h3>
                </div>
                <h4>{data.amount_formatted}</h4>
              </div>
            </label>
            <label for="radio-card-2" className="efi-radio-card">
              <input
                type="radio"
                name="radio-card"
                id="radio-card-2"
                checked={paymentType=="CARD"}
                onClick={() => setPaymentType("CARD")}
                value="CARD"
              />
              <div className="efi-custome-check-card">
                <div className="efi-check-pay">
                  <Image
                    className="payment-type-icon"
                    src={
                      window.location.origin + "/img/payment-type/card-icon.svg"
                    }
                    type="image/png"
                  />
                  <h3>CARD</h3>
                </div>
                <h4>{data.amount_formatted}</h4>
              </div>
            </label>
          </div>
          <div className="efi-widget-action-frame">
            <Button
              className="theme-primary-btn"
              onClick={() => setStep(2)}
            >
              Confirm
            </Button>
          </div>
        </Form>
    </>
  );
};

export default PaymentStepOne;
