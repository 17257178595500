import React, { useState } from 'react'
import { useTranslation } from 'react-multi-lang';
import TokenBuy from './TokenBuySec';
import BuySuccess from './BuySuccess';
import "./Buy.css"

const BuyIndex = () => {
    const t = useTranslation("buy")
    const [step, setStep] = useState(1);

  return (
    <div className="payment-link-sec">
        <div className="payment-link-box">
          <div className="payment-link-header-sec">
            <h3>{t("title")}</h3>
            <div className="payment-link-header-right-sec">
              
            </div>
          </div>
          <div className="recent-transaction-table-sec payment-link-table-sec table-responsive">
            {step == 1 ? <TokenBuy/> : step == 2 ? <BuySuccess/> : null}
          </div>
        </div>
      </div>
  )
}

export default BuyIndex
