import React, { useEffect, useState } from "react";
import PaymentStepOne from "./Steps/PaymentStepOne";
import PaymentStepTwo from "./Steps/PaymentStepTwo";
import PaymentStepThree from "./Steps/PaymentStepThree";
import PaymentStepFour, { PaymentInitiated } from "./Steps/PaymentStepFour";
import PaymentStepFive from "./Steps/PaymentStepFive";
import { useSelector } from "react-redux";

const PaymentWidgetPayMethod = ({ data }) => {
  const [step, setStep] = useState(1);
  const [paymentType, setPaymentType] = useState("CARD");
  const transactionComplete = useSelector(
    (state) => state.payment.transactionComplete
  );
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (
      !skipRender &&
      !transactionComplete.loading &&
      Object.keys(transactionComplete.data).length > 0
    ) {
      if (transactionComplete.data.redirect_url)
        window.location.assign(transactionComplete.data.redirect_url);
      else {
        if (transactionComplete.data.transaction.status == "USER_PAID" || transactionComplete.data.transaction.status == "TOKEN_RECEIVED")
          setStep(4);
        else if (transactionComplete.data.transaction.status == "INITIATED" || transactionComplete.data.transaction.status == "PROCESSING")
          setStep(5);
        else setStep(6);
      }
    }
    setSkipRender(false);
  }, [transactionComplete]);

  useEffect(()=> {
    (["PROCESSING"].includes(data.status) ? setStep(5) : ["REJECTED", "CANCELLED", "FAILED", "UNKNOWN"].includes(data.status) ? setStep(6) : ["TOKEN_RECEIVED", "USER_PAID"].includes(data.status) ? setStep(4) : setStep(1))
  }, [])

  return (
    <>
      <div className="efi-widget-right-card-frame">
        <div className="efi-widget-card-head">
          <ul className="list-unstyled pay-method-step">
            <li className="dashed-line-list">
              <div className={`method-step-card ${
                          step > 1 ? "completed" : ""
                        } ${step == 1 ? "active" : ""}`}>
                <p>
                  {step > 1 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 520 520"
                    >
                      <path
                        fill="#fff"
                        d="M239.987 460.841a10 10 0 01-7.343-3.213L34.657 243.463A10 10 0 0142 226.675h95.3a10.006 10.006 0 017.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0110.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 01-9.707 7.6z"
                        data-name="6-Check"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) : (
                    1
                  )}
                </p>
                <h5>Information</h5>

              </div>
            </li>
            <li className="dashed-line-list">
              <div className={`method-step-card ${
                          step > 2 ? "completed" : ""
                        } ${step == 2 ? "active" : ""}`}>
                <p>
                {step > 2 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 520 520"
                    >
                      <path
                        fill="#fff"
                        d="M239.987 460.841a10 10 0 01-7.343-3.213L34.657 243.463A10 10 0 0142 226.675h95.3a10.006 10.006 0 017.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0110.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 01-9.707 7.6z"
                        data-name="6-Check"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) : (
                    2
                  )}
                </p>
                <h5>Payment Method</h5>
              </div>
            </li>
            <li className="dashed-line-list">
              <div className={`method-step-card ${
                          step > 3 ? "completed" : ""
                        } ${step >= 3 ? "active" : ""}`}>
                <p>
                {step > 3 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 520 520"
                    >
                      <path
                        fill="#fff"
                        d="M239.987 460.841a10 10 0 01-7.343-3.213L34.657 243.463A10 10 0 0142 226.675h95.3a10.006 10.006 0 017.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0110.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 01-9.707 7.6z"
                        data-name="6-Check"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) : (
                    3
                  )}
                </p>
                <h5>Complete Order</h5>
              </div>
            </li>
          </ul>
        </div>
        <div className="efi-widget-method-main-frame">
          {step == 1 ? (
            <PaymentStepOne
              setStep={setStep}
              setPaymentType={setPaymentType}
              paymentType={paymentType}
              data={data}
            />
          ) : step == 2 ? (
            paymentType == "CARD" ? (
              <PaymentStepTwo
                setStep={setStep}
                paymentType={paymentType}
                data={data}
              />
            ) : (
              <PaymentStepThree
                setStep={setStep}
                paymentType={paymentType}
                data={data}
              />
            )
          ) : step == 4 ? (
            <PaymentStepFour
              paymentType={paymentType}
              transaction={transactionComplete}
            />
          ) : step == 5 ? (
            (<PaymentInitiated />)
          ) : <PaymentStepFive />}
        </div>
      </div>
    </>
  );
};

export default PaymentWidgetPayMethod;
