import React from "react";
import { useSelector } from "react-redux";

const PaymentWidgetDetails = ({ data }) => {

  const profile = useSelector((state) => state.admin.profile);

  function formatDate(dateString) {
    let timeZone = profile.data.timezone || "Asia/Kolkata";
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone,
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  }

  return (
    <>
      <div className="efi-widget-left-card-frame">
        <div className="efi-widget-card-head">
          <h2>Payment Information</h2>
        </div>
        <div className="efi-widget-card-main-frame">
          <div className="efi-widget-card-main-head">
            <h3>{data.token} {data.token_type}</h3>
            <h5>
              = {data.currency_symbol}{" "}{data.amount} <span>You’ll Pay</span>{" "}
            </h5>
          </div>
          <div className="efi-widget-details-card">
            <p>Order ID</p>
            <h4>{data.order_id}</h4>
          </div>
          <div className="efi-widget-details-card">
            <p>Reciever Wallet Address</p>
            <h4>{data.receiver_wallet_address.slice(0,10)}...{data.receiver_wallet_address.slice(-10)}</h4>
          </div>
          {/* <div className="efi-widget-details-card">
            <p>Estimated Rate</p>
            <h4>1 BTC =  ₹43,675</h4>
          </div>
          <div className="efi-widget-details-card">
            <p>Platform Fee (2%)</p>
            <h4>₹45</h4>
          </div> */}
          {data.created_utc ? <div className="efi-widget-details-card">
            <p>Date</p>
            <h4>{formatDate(data.created_utc)}</h4>
          </div> : null}
        </div>
      </div>
    </>
  );
};

export default PaymentWidgetDetails;
