import React from 'react';
import { Image } from "react-bootstrap";

const PageLoader = ({ main = false }) => {
  return (
   <div className="page-loader-sec">
      <div class="loader"></div> 
   </div>
  );
}

export default PageLoader;