import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import Helmet from "react-helmet";
import configuration from "react-global-configuration";
import { Image } from "react-bootstrap";

const ForgotPasswordIndex = () => {
  const t = useTranslation("forgot");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgotPassword = useSelector((state) => state.admin.forgotPassword);

  const [skipRender, setSkipRender] = useState(true);

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
  });

  const handleSubmit = (values) => {
    dispatch(forgotPasswordStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !forgotPassword.loading &&
      Object.keys(forgotPassword.data).length > 0
    )
      navigate("/login");
    setSkipRender(false);
  }, [forgotPassword]);

  return (
    <>
      <div className="efi-auth-sec">
        <div className="container">
          <div className="header-logo">
            <div className="efi-logo-sec">
              <a href="https://efimarkets.com">
              <Image
                className="logo logo-filter"
                src="/img/logo.png"
                // src={configuration.get("configData.site_logo")}
              />
              </a>
            </div>
          </div>
        </div>
        <div className="row align-items-center g-0">
          <div className="col-md-4">
            <div className="auth-efi-background-further">
              <div className="efi-widget-auth-info-frame">
                <h3>Forgot Your Password?</h3>
                <p>Oh no! It happens to the best of us. No worries, we'll help you get back on track.</p>
                <div className="efi-auth-widget-action">
                  <Link className="efi-outline-btn" to="/login"> {t("login")}</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="auth-efi-background-form">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-7 col-xl-7">
                  <div className="efi-auth-box">
                    <div className="efi-auth-title-sec text-center">{t("heading")}</div>
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={forgotPasswordSchema}
                      onSubmit={handleSubmit}
                    >
                      <FORM className="efi-auth-form">
                        <div className="mb-3">
                          <label for="exampleInputEmail1" className="form-label">
                            {t("email.label")}
                          </label>
                          <Field
                            name="email"
                            className="form-control"
                            placeholder={t("email.placeholder")}
                            type="text"
                            autoFocus={true}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </div>
                        <div className="efi-ath-btn-sec">
                          <button
                            className="efi-auth-btn"
                            disabled={forgotPassword.buttonDisable}
                          >
                            {!forgotPassword.buttonDisable && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                              </svg>
                            )}
                            {forgotPassword.buttonDisable ? (
                              <ButtonLoader varient="black" />
                            ) : (
                              t("submit_btn.text")
                            )}
                          </button>
                        </div>
                      </FORM>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordIndex;
