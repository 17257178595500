import React from "react";
import { Image, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { transactionCompleteStart } from "../../../../store/slices/PaymentSlice";

const PaymentStepThree = (props) => {

  const dispatch = useDispatch();
  const transactionComplete = useSelector(state => state.payment.transactionComplete);

  return (
    <>
      <div className="step-payment-one">
        <div className="efi-widget-method-main-title">
          <h3>UPI</h3>
          <Button onClick={() => props.setStep(1)}>
            Other Payment Method
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              x="0"
              y="0"
              enableBackground="new 0 0 240.823 240.823"
              version="1.1"
              viewBox="0 0 240.823 240.823"
              xmlSpace="preserve"
              fill="#5A36A8"
            >
              <path d="M183.189 111.816L74.892 3.555c-4.752-4.74-12.451-4.74-17.215 0-4.752 4.74-4.752 12.439 0 17.179l99.707 99.671-99.695 99.671c-4.752 4.74-4.752 12.439 0 17.191 4.752 4.74 12.463 4.74 17.215 0l108.297-108.261c4.68-4.691 4.68-12.511-.012-17.19z"></path>
            </svg>
          </Button>
        </div>
        <div className="payment-details-wrapped">
          <div className="payment-details-info">
            <div className="payment-details-avater-frame text-center">
              <Image
                className="payment-avater-frame"
                src={window.location.origin + "/img/widgets/pay-type.png"}
                type="image/png"
              />
            </div>
            <div className="payment-details-amount">
              <p>Pay</p>
              <h2>{props.data.amount_formatted}</h2>
            </div>
          </div>
          <div className="efi-widget-action-frame mb-3">
            <Button
              className="theme-primary-btn"
              disabled={transactionComplete.buttonDisable}
              onClick={() => dispatch(transactionCompleteStart({
                payment_type: props.paymentType,
                order_id: props.data.order_id,
              }))}
            >
              Pay
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentStepThree;
