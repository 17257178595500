import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import useLogout from "../../../hooks/useLogout";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import { profileStart } from "../../../store/slices/AdminSlice";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { setLanguage } from "react-multi-lang";
import { useTranslation } from "react-multi-lang";
import { chatSupportUnreadListStart, chatSupportUnreadListSuccess, setActiveChatSupport } from "../../../store/slices/PayoutSlice";

let pusher;

const AdminHeader = () => {
  const t = useTranslation("admin_header");
  const navigate = useNavigate();
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const location = useLocation();
  const chatSupportUnreadList = useSelector((state) => state.payout.chatSupportUnreadList);
  const activeChatSupport = useSelector(state => state.payout.activeChatSupport);
  const [chatWidget, setChatWidget] = useState(false);

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  const [languageOption, setLanguageOption] = React.useState(null);
  const [channel, setChannel] = useState(null);

  const options = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
    { value: "jp", label: "Japanese" },
  ];

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (lang) {
      const selectedOption = options.find((option) => option.value === lang);
      setLanguageOption(selectedOption);
      setLanguage(selectedOption.value);
    } else {
      setLanguage(options[0].value);
      setLanguageOption(options[0]);
    }
  }, []);

  return (
    <>
      <div className="admin-ui-header-sec">
        <Navbar expand="lg">
          <Container fluid>
            <div className="admin-ui-title-sec">
              <div
                className="admin-ui-collapse-btn"
                onClick={() => dispatch(toggleSidebar())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5A36A8"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM6.5 20h-5c-.83 0-1.5.67-1.5 1.5S.67 23 1.5 23h5c.83 0 1.5-.67 1.5-1.5S7.33 20 6.5 20zm8-9h-13c-.83 0-1.5.67-1.5 1.5S.67 14 1.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
                </svg>
              </div>
              <h2>{location.pathname == "/" && "Dashboard"}</h2>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fillRule="evenodd"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 1707 1707"
              >
                <path
                  fill="#5A36A8"
                  d="M1453 1698H254c-140 0-254-114-254-253V262C0 122 114 9 254 9h1199c140 0 253 113 253 253v1183c0 139-113 253-253 253z"
                  data-original="#6c6c6c"
                ></path>
                <path
                  fill="#fff"
                  d="M1295 536H412c-76 0-137-61-137-137 0-75 61-136 137-136h883c75 0 136 61 136 136 0 76-61 137-136 137zm-139 454H551c-76 0-137-61-137-137 0-75 61-136 137-136h605c76 0 137 61 137 136 0 76-62 137-137 137zm139 454H412c-76 0-137-61-137-137 0-75 61-136 137-136h883c75 0 136 61 136 136 0 76-61 137-136 137z"
                  data-original="#ffffff"
                ></path>
              </svg>
            </Navbar.Toggle>
            {profile.loading ? (
              <Row>
                <Col>
                  <Skeleton circle width={50} height={50} />
                </Col>
                <Col>
                  <Skeleton width={100} height={50} />
                </Col>
              </Row>
            ) : Object.keys(profile.data).length > 0 ? (
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto" style={{ gap: "1em" }}>
                  <div className="user-dropdown-sec">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <div className="admin-user-details">
                          <CustomLazyLoad
                            className="admin-user-img"
                            src={profile.data.picture}
                          />
                          <span>{profile.data.name}</span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => navigate("/account-settings")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                          </svg>
                          <span>{t("heading")}</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={logout}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#6E6D8E"
                              fillRule="evenodd"
                              d="M5.636 5.636C8.582 2.691 13.059 2.215 16.5 4.204a1 1 0 01-1 1.731 7 7 0 100 12.13 1 1 0 011 1.731c-3.441 1.99-7.918 1.514-10.864-1.432a9 9 0 010-12.728zm12.657 2.657a1 1 0 011.414 0l1.891 1.891c.179.179.353.352.488.512.148.175.308.396.402.686a2 2 0 010 1.236 2.02 2.02 0 01-.402.687 9.18 9.18 0 01-.488.511l-1.89 1.891a1 1 0 01-1.415-1.414L19.586 13H12a1 1 0 110-2h7.586l-1.293-1.293a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>{t("logout")}</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Nav>
              </Navbar.Collapse>
            ) : null}
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default AdminHeader;
