import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";

const HeaderIndex = (props) => {

    return (
        <>
            <header className="header-nav-center">
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand href="#home">
                            <Image
                                className="logo"
                                src={
                                    window.location.origin + "/images/logo.png"
                                }
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav">
                            <Image
                                className="lazyload hamburger-menu"
                                src={
                                    window.location.origin + "/images/hamburger-menu.png"
                                }
                            />
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link href="#">Composable 998</Nav.Link>
                                <Nav.Link href="#">Connect Wallet</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    );
};

export default HeaderIndex;
