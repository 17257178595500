import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Image, Modal } from "react-bootstrap";
import "./PaymentLayout.css";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { transactionUpdateStart } from "../../store/slices/PaymentSlice";
import Select from "react-select";
import axios from "axios";
import PaymentCancel from "./PaymentCancel";
import configuration from "react-global-configuration";
import { checkPaymentMethodStart } from "../../store/slices/TraderSlice";
import "./PaymentLayout.css"
import { useTranslation } from "react-multi-lang";
import { apiUrl } from "../../Environment";
import { useLocation, useNavigate } from "react-router-dom";

const PayInOne = (props) => {
  const t = useTranslation("payin_one")
  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px !important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const location = useLocation();
  const [show, setShow] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [token, setToken] = useState({
    type: "ETH",
    value: 0,
  });

  const transactionView = useSelector((state) => state.payment.transactionView);
  const transactionUpdate = useSelector((state) => state.payment.transactionUpdate);
  const checkPaymentMethod = useSelector((state) => state.trader.checkPaymentMethod);

  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("email.required")).required(t("email.invalid")),
  });

  const handleSubmit = (values) => {
    let data = {};
    setUserEmail(values.email);
    if(transactionView.data.transaction.payment_type == "inr")
      {
        dispatch(checkPaymentMethodStart({
          amount: transactionView.data.transaction.total,
          order_id: transactionView.data.transaction.order_id,
          whitelabel_merchant_id: transactionView.data.transaction.reference_id,
          payment_method: transactionView.data.transaction.inr_type,
          callback_url: apiUrl + "user_transaction_callback",
        }));
      }
      else{
        if (transactionView.data.transaction.payment_type == "crypto")
          data = { ...data, crypto_type: token.type };
        dispatch(
          transactionUpdateStart({
            step: 1,
            transaction_unique_id: transactionView.data.transaction.unique_id,
            user_email: values.email,
            ...data,
          })
        );
      }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !checkPaymentMethod.loading &&
      Object.keys(checkPaymentMethod.data).length > 0
    ) {
      // props.setStep(2);
      if(checkPaymentMethod.data.payment_method == "UPI_INTENT")
      {
        window.location.replace(checkPaymentMethod.data.redirect_url);
      } else {
        localStorage.setItem("qr_code", checkPaymentMethod.data.payment_details.qr_code);
        props.setPaymentData(checkPaymentMethod.data);
        let data = {};
        if (transactionView.data.transaction.inr_type == "UPI") {
          data = {...data, upi_id: checkPaymentMethod.data.payment_details.upi_id }
        } else {
          data = {...data, account_number: checkPaymentMethod.data.payment_details.account_number }
        }
        dispatch(
          transactionUpdateStart({
            step: 1,
            transaction_unique_id: transactionView.data.transaction.unique_id,
            user_email: userEmail,
            ...data
          })
        );
      }
    }
    setSkipRender(false);
  }, [checkPaymentMethod]);

  useEffect(() => {
    if (
      !skipRender &&
      !transactionUpdate.loading &&
      Object.keys(transactionUpdate.data).length > 0
    ) {
      if(transactionView.data.transaction.payment_type == "inr")
      {
        // dispatch(checkPaymentMethodStart({
        //   amount: transactionView.data.transaction.total,
        //   order_id: transactionView.data.transaction.order_id,
        //   payment_method: transactionView.data.transaction.inr_type,
        // }));
      }
      else{
        localStorage.setItem("qr_code", transactionUpdate.data.qr_code);
        props.setPaymentData(transactionUpdate.data)
        // props.setStep(2);
      }
      props.setStep(2);
    }
    setSkipRender(false);
  }, [transactionUpdate]);

  const options = [
    { value: "ethereum", label: "ETH" },
    { value: "binance-usd-linea", label: "BUSD" },
    { value: "usd-coin", label: "USDC" },
    { value: "tether", label: "USDT" },
  ];

  useEffect(() => {
    if (transactionView.data.transaction.payment_type == "crypto") {
      getTokenValue("ethereum", "ETH");
    }
  }, []);

  const getTokenValue = async (from, type) => {
    if (!localStorage.getItem(from)) {
      const token_value = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${from}&vs_currencies=usd`
      );
      if (token_value.data[from] && token_value.data[from]["usd"]) {
        localStorage.setItem(from, token_value.data[from]["usd"]);
        setToken({
          type: type,
          value:
            transactionView.data.transaction.total /
            token_value.data[from]["usd"],
        });
      }
    } else {
      setToken({
        type: type,
        value:
          transactionView.data.transaction.total / localStorage.getItem(from),
      });
    }
  };

  return (
    <>
      <div className="efi-payment-form-wrap">
        <div className="efi-form-wrap-top">
          <div className="form-heading">
            <h2>{t("heading")}</h2>
          </div>
          <div className="efi-total-amount-frame">
            <p>{t("total")} :</p>
            <h3>{transactionView.data.transaction.formatted_total}</h3>
            <span></span>
            <span className="service-fee">
              {t("service")}: {configuration.get("configData.currency")}{" "}
              {transactionView.data.transaction.merchant_commission_amount +
                transactionView.data.transaction.admin_commission_amount}
            </span>
          </div>
        </div>
        <div className="middle-wrap">
          <div className="efi-form-wrap-middle">
            <div className="efi-pay-method-info">
              <p>{t("payment_method")}</p>
              <h4>{transactionView.data.transaction.payment_type_formatted}</h4>
            </div>
            {transactionView.data.transaction.inr_type ? <div className="efi-pay-method-info">
              <p>{t("inr_type")}</p>
              <h4>{transactionView.data.transaction.inr_type_formatted}</h4>
            </div> : null}
            <div className="efi-pay-method-info">
              <p>{t("order_id")}</p>
              <h4>{transactionView.data.transaction.order_id}</h4>
            </div>
            {transactionView.data.transaction.payment_type == "crypto" && (
              <div className="efi-pay-method-info">
                <p>{t("token_type")}</p>
                <div className="total-amount-crypt">
                  <h4>
                    {transactionView.data.transaction.formatted_total}{" "}
                    <span> = {token.value}</span>
                  </h4>
                  <Select
                    options={options}
                    styles={customStyles}
                    isSearchable={false}
                    onChange={(token) =>
                      getTokenValue(token.value, token.label)
                    }
                    placeholder={
                      <div className="placeholder-flex">{token.type}</div>
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="efi-form-wrap-bottom">
            <Formik
              initialValues={{
                email: transactionView.data.transaction.user_email,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <FORM>
                  <div className="form-group-frame">
                    <Form.Label>{t("email.name")} </Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="email-input">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group 1815">
                            <path
                              id="Vector"
                              d="M18 10.7835C18 13.1084 15.984 14.9917 13.473 15H13.464H4.545C2.043 15 0 13.1251 0 10.8001V10.7918C0 10.7918 0.00539994 7.1036 0.0125999 5.24864C0.0134999 4.90032 0.4455 4.70533 0.7398 4.92199C2.8782 6.49278 6.7023 9.35685 6.75 9.39435C7.389 9.8685 8.199 10.136 9.027 10.136C9.855 10.136 10.665 9.8685 11.304 9.38518C11.3517 9.35602 15.0903 6.57778 17.2611 4.98115C17.5563 4.76366 17.9901 4.95865 17.991 5.30614C18 7.14693 18 10.7835 18 10.7835Z"
                              fill="#D3D5D7"
                            />
                            <path
                              id="Vector_2"
                              d="M17.5283 2.22826C16.7489 0.868303 15.2153 0 13.5269 0H4.5449C2.8565 0 1.3229 0.868303 0.543495 2.22826C0.368895 2.53241 0.451695 2.91157 0.742395 3.12656L7.42491 8.07558C7.89291 8.42556 8.45991 8.59972 9.02691 8.59972H9.03591H9.04491C9.61191 8.59972 10.1789 8.42556 10.6469 8.07558L17.3294 3.12656C17.6201 2.91157 17.7029 2.53241 17.5283 2.22826Z"
                              fill="#D3D5D7"
                            />
                          </g>
                        </svg>
                      </InputGroup.Text>
                      <Field
                        name="email"
                        type="email"
                        className="form-control"
                        value={values.email}
                        autoFocus={true}
                        placeholder={t("email.placeholder")}
                      />
                    </InputGroup>
                    <ErrorMessage
                      component={"div"}
                      name="email"
                      className="errorMsg"
                    />
                  </div>
                  <div className="form-wrap-btn">
                    <Button
                      type="button"
                      className="btn-cancel"
                      onClick={handleShow}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      className="btn-next"
                      disabled={transactionUpdate.buttonDisable || checkPaymentMethod.buttonDisable}
                    >
                      {(transactionUpdate.buttonDisable || checkPaymentMethod.buttonDisable) ? "Loading" : "Next"}
                    </Button>
                  </div>
                </FORM>
              )}
            </Formik>
            {show && <PaymentCancel show={show} handleClose={handleClose} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayInOne;
